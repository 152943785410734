import React, { useEffect } from "react";
import { Navbar, Sidebar } from "../../components/Shared";

function Terms() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<div className="flex flex-col items-center justify-center my-4 mt-10 space-y-8">
					<h1 className="text-5xl text-clr-dark-purple font-jihoBold py-5 font-bold">
						Terms and Conditions
					</h1>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<p className="ml-5 text-base text-clr-gray">
							These global Terms and Conditions are entered into by and between
							you and Phydias SA, a company registered in Switzerland with
							company registration ID CH-660.2.411.001-7 with the registered
							address at rue des Pâquis 8 1202 Genève, Switzerland, with which
							your Account is held (referred to herein as “CashCare”) and govern
							your use of the CashCare Prepaid, Debit or Credit Cards.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							By ordering a CashCare Prepaid, Debit or Credit Card, you agree to
							be bound by these Terms and Conditions and you should therefore
							read these Terms and Conditions carefully. If you have any
							questions in respect of anything set out in these Terms and
							Conditions please contact support@cashcare.co.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							1. Definitions and Construction
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							Account – means the corresponding CashCare account consisting of
							one or more of Currency Wallets which together comprise your
							Usable Balance; Applicable Law – means any law, rule, statute,
							subordinate legislation, regulation, by-law, order, ordinance,
							protocol, code, guideline, treaty, policy, notice, direction or
							judicial, arbitral, administrative, ministerial or departmental
							judgment, award, decree, treaty, directive, or other requirement
							or guideline published or in force at any time which applies to or
							is otherwise intended to govern or regulate any person (including
							all parties to these Terms and Conditions), property, transaction,
							activity, event or other matter, including any rule, order,
							judgment, directive or other requirement or guideline issued by
							any governmental or regulatory authority whether in the Territory
							or outside; Appointed Processor – means a third party appointed by
							CashCare for the purposes of collecting and using KYC Information.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							ATM – means an automated teller machine that accepts the Card for
							cash withdrawals and balance enquiries.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Automated Currency Transfer – means the automatic transfer of
							funds from one Currency Wallet, to another Currency Wallet with
							sufficient funds so as to allow a transaction to be completed.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Card – means the CashCare physical payment card issued and
							available to you for your use, upon activation and subject to you
							verifying Your Account.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Card Issuer – means Phydias SA, a company registered in
							Switzerland with company registration number CH-660.2.411.001-7
							and registered address at rue des Pâquis 8 1202 Genève,
							Switzerland.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Card Replacement Fee - means the applicable fee for issuing a
							replacement card, if applicable, as stated in the Fees Section.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Conversion Rate - means the applicable exchange rate as determined
							by CashCare from time to time and set out in the Fees Section.
							Generally, for major currencies during FX market hours the
							standard exchange rate will be the interbank rate. However, in
							certain circumstances, it is not possible for the standard
							exchange rate to be the interbank rate including when the FX
							market is closed on weekends or on public holidays or when your
							Conversion Rate involves illiquid currencies. It may also not be
							possible for the standard exchange rate to be the interbank rate
							due to other external factors such as volatile market conditions,
							a system failure of CashCare or any other external factors. In
							such instances, the standard exchange rate will be different from
							the interbank rate. Please see the Fees Section for more details.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							We will use the Exchange Rates set out in the Fees Section, where
							we can, however you will be informed of the exact Conversion Rate
							for each CashCare Transaction prior to your entry into the same.
							It is your responsibility to ensure that you are happy with the
							Conversion Rate we offer you, prior to entering into each
							transaction.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Currency Wallet - means a part of your Account that holds funds in
							a particular Supported Currency in your Account.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Customer Support – means our online support function which can be
							contacted on support@cashcare.co.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Default Wallet – means the Currency Wallet linked to your physical
							CashCare Card from which you are making a transaction.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Fees Section – means the schedule of fees and charges in respect
							of your Account, which can be found here.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Force Majeure Event - means any cause beyond the control of a
							party including without limitation, acts of God, acts of civil or
							military authority, fires, strikes, lockouts or labor disputes,
							epidemics, governmental restrictions, wars, terrorist acts, riots,
							earthquakes, storms, typhoons, floods and breakdowns in electronic
							and computer information and communications systems.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							KYC – means “know-your-customer”.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							KYC Information – means your personal identification information,
							documentation and other evidence as CashCare may request from you
							from time to time in respect of your Account and/or Card.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Negative Balance – refers to when the monetary value in your
							Account falls below zero. Payment Processor means the third party
							used by CashCare to fund a fiat currency transaction.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							PIN – means the personal identification number You use to enable
							your Card to undertake physical transactions involving your Card,
							and/or ATM withdrawals.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Prohibited Articles – means (1) drug paraphernalia or narcotics,
							steroids, certain controlled substances or other products that
							present a risk to consumer safety, (2) stolen goods including
							digital and virtual goods or which encourage, promote, facilitate
							or instruct others to engage in illegal activity (3) the promotion
							of hate, violence, racial intolerance or the financial
							exploitation of a crime, (4) items that are considered obscene,
							(5) items that infringe or violate any copyright, trademark, right
							of publicity or privacy or any other proprietary right under
							Applicable Laws, (6) ammunition, firearms, certain firearm parts
							or accessories, or certain weapons or knives regulated or
							prohibited under Applicable Laws; (7) are associated with
							purchases of annuities or lottery contracts, lay-away systems,
							offshore banking or transactions to finance or refinance debts
							funded by a credit card, (8) are for the sale of certain items
							before the seller has control or possession of the item, (9) are
							by payment processors to collect payments on behalf of merchants,
							(10) are associated with the sale of traveller's checks or money
							orders, (11) involve certain credit or debt settlement services,
							credit transactions or insurance activities, (12) involve offering
							or receiving payments for the purpose of bribery or corruption; or
							(13) any automated fuel dispenser transactions.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Prohibited Countries – means any of the Democratic People’s
							Republic of Korea, Democratic Republic of Congo, Eritrea, Libya,
							Somalia, South Sudan, Sudan, Yemen, Myanmar, Venezuela, Iran,
							Cuba, Syria, Mali, Central African Republic, Guinea-Bissau,
							Lebanon or any other country which (or with any other persons who)
							is sanctioned by the United Nations Security Council, or under
							Applicable Laws in the Territory.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Supported Currency – means the fiat currencies supported by the
							Currency Wallets in your CashCare Account, as notified to you from
							time to time on the CashCare Dashboard.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Territory means Switzerland.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Unsupported Currency – means a currency other than a Supported
							Currency.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Usable Balance – means the total monetary value available to you
							in your Account for transactions using the Card, being the total
							value of all Currency Wallet(s) less any applicable tolerance
							limits and Fees, which apply to Your transactions.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							USDR – the Stablecoin, is a cryptographic digital asset issued by
							CashCare, representing a claim on an underlying reserve of assets.
							USDR is designed to maintain a stable value in relation to the
							United States Dollar (USD) through a mechanism that ensures a
							one-to-one pegging. The underlying reserve assets may include, but
							are not limited to, USD, cash equivalents, and other assets as
							determined by the issuer. USDR facilitates secure and efficient
							transactions within digital ecosystems, while adhering to the
							financial regulations and standards of the United States. It is
							the intention of CashCare to provide transparency and regulatory
							compliance in the issuance, redemption, and operation of USDR,
							subject to applicable laws and regulatory requirements.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Verification - means the process of verifying your Account through
							the submission of requested documents as indicated in the CashCare
							Website and/or CashCare app. Website – means the website at the
							URLs{" "}
							<a href="https://cashcare.co/" className="font-bold">
								www.cashcare.co
							</a>{" "}
							and/or any future URL applicable to your Account and/or Card. We,
							us, or our – means CashCare.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							CashCare Dashboard – means the Website or CashCare app that you
							log on to, to access the secure elements of your Account.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							You or Your – means the natural person named on the Account
							entering into these Terms and Conditions.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							In these Terms and Conditions, unless the context otherwise
							requires:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) a reference to this Terms and Conditions or to these Terms
								and Conditions is a reference to these Terms and Conditions as
								may be varied, amended, supplemented, and/or replaced from time
								to time;
							</li>
							<li>
								(b) words in the singular include the plural and vice versa;
							</li>
						</ul>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							2. Introduction
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.1- Any capitalized terms used in these Terms but which are not
							defined in these Terms and Conditions are defined in CashCare’s
							Current Account General Terms of Service.
						</h3>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.2- These Terms should be read in conjunction with:
						</h3>
						<ul className="ml-14 text-clr-gray">
							<li>A. CashCare’s Privacy and Data Safety Policy</li>
							<li>B. CashCare’s User Agreement</li>
							<li>C. Any other terms between You and CashCare </li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.3- Where there is any conflict between any provisions contained
							in the terms referenced at Clause 2.2 (A) to (C) above, the
							following order of priority shall be applied;
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							for the avoidance of doubt the first mentioned taking precedence
							over those subsequently following:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>A. CashCare’s Privacy and Data Safety Policy</li>
							<li>B. CashCare’s User Agreement</li>
							<li>C. These Terms</li>
							<li>D. Any other terms between You and CashCare </li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.4- Your CashCare Card is issued by Phydias SA, a company
							registered in Switzerland with company registration number
							CH-660.2.411.001-7 registered at rue des Pâquis 8 1202 Genève,
							Switzerland.
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							The Card Issuer, as the holder of the stored value facility in
							respect of the Currency Wallets (described below), also holds the
							fiat currency loaded on your CashCare Card.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.5- Conditions
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							We shall be entitled at our sole discretion, to (i) refuse your
							application or, having accepted your application to register or
							accepted you through our Verification process, terminate your use
							of the Card and/or Your Account if you fail to fulfill any one of
							the following criteria, and/or (ii) change the eligibility
							criteria for registration at any time. By signing up for a Card
							and the corresponding Account, you represent and warrant that:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>(a) you are at least eighteen (18) years of age;</li>
							<li>
								(b) you are eligible to register for and use the Card and the
								corresponding Account in accordance with the Verification
								process;{" "}
							</li>
							<li>
								(c) you have the full right, power, and legal authority to enter
								into this Agreement; and{" "}
							</li>
							<li>
								(d) you are not impersonating any other person, operating under
								an alias or otherwise concealing your identity.{" "}
							</li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.6- Signing up, KYC and Personal Data
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							2.6.1- To complete the Verification process and to receive your
							Card, you acknowledge and agree that CashCare and/or CashCare’s
							Appointed Processor shall be entitled to undertake identification,
							credit, anti-money laundering, counter terrorism financing, KYC
							and other necessary checks and screenings required to comply with
							CashCare’s internal compliance policies and Applicable Laws, at
							any time we or our Appointed Processor deems necessary in our or
							their sole discretion.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.62- You agree to assist CashCare and its Appointed Processor in
							these checks by supplying us or our Appointed Processor, as
							directed, with the applicable KYC information request from time to
							time. You shall submit all requested KYC Information promptly to
							CashCare as we or you acknowledge that CashCare and our Appointed
							Processor is authorized to collect your personal data and to
							conduct such checks and screenings as described in these Terms and
							Conditions on CashCare’s behalf.{" "}
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.6.3- You represent and warrant that you have the power and
							authority to provide all KYC Information which you submit to us.{" "}
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.6.4- We reserve the right to refuse to load any Account or Card,
							and may suspend or terminate your use of the Card if (a) you fail
							to submit satisfactory KYC Information to be determined at
							CashCare’s discretion; or (b) you submit inaccurate or incomplete
							KYC Information; or (c) we are unable to verify your identity
							based on the KYC Information you submit or (d) at any time we
							suspect that you are using your Account for any purpose which is
							prohibited under these Terms and Conditions.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.7- Personal Data
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							For information on the Personal Data that CashCare collects and
							uses in respect of your Account and your use of the Card, please
							see our{" "}
							<a href="https://cashcare.co/privacy" className="font-bold">
								Privacy and Data Safety Policy.
							</a>
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.8- Change of personal details
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You represent and warrant that all the information you provide to
							us (including all KYC Information) is true, accurate and complete.
							Should you become aware of any inaccuracies, or change your
							contact information or any other personal details (for example
							your address telephone number or your email address), you must
							notify us promptly by logging onto your CashCare Dashboard or by
							contacting Customer Support. We will not be responsible if you do
							not receive any notice or correspondence that has been sent in
							accordance with these Terms and Conditions as a result of our
							reliance on any incorrect, incomplete or obsolete personal details
							which you have previously submitted and failed to update. In
							addition, if you do not provide us with accurate and complete
							information, we may not be able to provide you with the products
							or services requested under these Terms and Conditions
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.9- Consents for Collection, Use and Disclosure
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Without prejudice to any of the consents provided to us by you in
							these Terms and Conditions, you hereby irrevocably and
							unconditionally consent and authorize us to, at any time and at
							our absolute discretion to collect, use, process and store any and
							all of your personally identifiable information, credit or
							financial information provided to us, including your KYC
							Information; and disclose any and all of your personally
							identifiable information, credit or financial information,
							including your KYC Information, to the Card Issuer and/or other
							CashCare affiliates, financial institutions, debt collection
							agencies, credit bureaus, any credit reporting agency, or any
							other third party service providers of CashCare for all purposes
							associated with our provision of services under these Terms and
							Conditions.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.10- Using Your Card
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							2.10.1- Please note that your Card is not a credit card and does
							not incorporate or allow any credit facility. Transactions made
							using the Card will only be successful to the extent your Account
							holds a sufficient Usable Balance to settle transactions and you
							will not be able to complete transactions in the event you have a
							Negative Balance. The Card is a prepaid and debit card that will
							debit the relevant Supported Currency within your Account when
							used. You can use your Card to complete transactions, which will
							deplete your Usable Balance, debiting the relevant Supported
							Currency Account. You will need to activate your Card and load
							funds onto your Account in accordance with these Terms and
							Conditions in order to use your Card to make purchases. Subject to
							any limitations contained in these Terms and specifically those at
							clause 5.8, once your Card is activated and loaded, you may use
							your Card to:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) purchase goods and services from merchants which accept
								payment via your Card.
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							2.10.2- The Account tied to your Card is also not a savings or
							checking account and no interest whatsoever shall be due or
							payable to you in respect of any part of the Usable Balance stored
							in your Account.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							<span>
								2.10.3- When CashCare holds funds for you, we cannot and will
								not use the funds to invest or lend to other persons or
								entities.
							</span>
							<span>
								Please note that you may not be able to recover all the money
								you paid if CashCare’s business fails.
							</span>
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.10.4- For the avoidance of doubt, CashCare is not a party to any
							transaction for sale and purchase of goods and services between
							you and any merchant. The rights and obligations in connection
							with (a) the sale and provision of goods and services which are
							the subjects of such transactions, (b) the transaction methods and
							terms, and (c) performance or non-performance of the transactions
							(including but not limited to refusal to perform, delay, non-
							delivery, defects, errors, return or replacement of goods and
							services, and refund), exist only between you and such merchant,
							and CashCare has no control over and is not responsible for the
							delivery, quality, safety, legality, fitness for purpose or any
							other aspect of goods or services that you purchase  with the Card
							or Account. CashCare cannot and does not guarantee or ensure that
							the merchant you are dealing with will complete the transaction or
							that the merchant is authorized to do so.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.11- Loading your Account
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You may load your Account in the Supported Currencies via the
							CashCare Dashboard from time to time as required. Upon
							successfully loading or topping up your Account, CashCare will
							issue you with a notification.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.11.1- The amount of funds which you may load onto your Account
							is subject to the load limits outlined in Clause 5.7.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.12- Your PIN
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You must log in to your CashCare Dashboard to activate your Card
							and follow the instructions online to select your PIN to enable
							use of your Card. You are at all times responsible for maintaining
							adequate security and control of your PIN or any other codes that
							you use to access the services provided under these Terms and
							Conditions. Once you have successfully activated your Card, set up
							your payment PIN, and loaded funds onto your Account, you may use
							your Card to conduct transactions in accordance with these Terms
							and Conditions. Your selected PIN must be designated by you and
							must not be shared or stored with any other person. If you do so,
							you will remain liable and responsible for all Card activity
							carried out by the other person.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Where you believe the security of your PIN has been compromised,
							please contact CashCare Support on support@cashcare.co and follow
							the procedures we instruct.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.13- Protecting your Card, PIN and Password.
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							2.13.1- Your Card and the corresponding Account shall be for your
							use only and may only be used after the Card has been activated in
							accordance with these Terms and Conditions. You are responsible
							for keeping your PIN and password confidential and secure. You
							must not:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>(a) keep any written record of your PIN or password;</li>
							<li>(b) allow another person to see your PIN or password; </li>
							<li>
								(c) keep your PIN or password in a form that can be readily
								identified as the PIN or password to your Card
							</li>
							<li>
								(d) disclose your PIN or password to any other person (including
								your family members or spouse); or{" "}
							</li>
							<li>
								(e) carry your PIN or password with your Card or store them in
								your mobile devices.
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							2.13.2- You must set a password to be able to log into your
							CashCare Dashboard. Using your Card, go to the Website and set up
							your access to the CashCare Dashboard.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							2.13.3- If you forget your password or believe it may have been
							compromised, you should contact Customer Support immediately on
							support@cashcare.co. You may reset your password by logging into
							your CashCare Dashboard at any time you wish.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.14- Moving overseas
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Should you change your country of residence to another country or
							your country becomes unsupported by CashCare after signing up for
							your Card, you may be prevented from loading funds to your
							Account. You will however still be able to (a) use any positive
							Usable Balance remaining in your Account to conduct transactions
							or cash withdrawals in accordance with these Terms and Conditions
							until the expiry date stated on your applicable Card or until your
							Usable Balance is fully utilized, whichever occurs first; or (b)
							obtain a refund of your Usable Balance
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							2.15- Contacting Customer Support
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Unless otherwise stated in these Terms and Conditions, should you
							have any request, issue, or any complaint regarding the Card, you
							may contact Customer Support any day from Monday to Sunday by
							email to support@cashcare.co.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							You agree that CashCare may (i) monitor and/or record any
							conversations you have with our Customer Support officers; and
							(ii) use such recordings or electronic transcripts from such
							recordings for quality assurance and training purposes or as
							evidence in any dispute or anticipated dispute.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Should you urgently need to disable or block any transaction or
							ATM withdrawal undertaken using your Card, you may access your
							CashCare Dashboard.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							3. Multi Currency Wallets
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							3.1- You may load supported Currency Wallets in your Account with
							the applicable Supported Currency. Where you provide funds in an
							Unsupported Currency, the Payment Processor may first convert such
							sums of Unsupported Currency into an equivalent amount in your
							selected Supported Currency (at the applicable prevailing exchange
							rate). Funds transferred between your Currency Wallets will also
							be subject to the prevailing Conversion Rate.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							4. Using your Account
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							4.1. Locations of Use
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							4.1.1 Only you, the cardholder, is authorized to use your Card and
							the associated Account. You can use your Card to access the Usable
							Balance on your Account to purchase goods and services from
							Visa-supported merchants worldwide, who accept the Card. However,
							where a merchant or financial institution does not accept payment
							via your Card or Account, CashCare shall not be liable in such
							cases. You may also use your Card to withdraw Usable Balance on
							your Account at any ATM which accepts your Card. Your Card and its
							corresponding Account may only be used to make any transactions or
							withdrawals if it has a positive Usable Balance. We will not
							authorize transactions for a transaction or withdrawal amount
							which is larger than your Usable Balance. Where such transactions
							are processed, you shall be liable to us for any resulting
							Negative Balance plus any applicable fees, along with any costs
							incurred by us or any other relevant third party in recovering or
							attempting to recover from you the amount of Negative Balance that
							you owe. Where we permit a Negative Balance to be incurred, it
							shall not be deemed to set any precedent for subsequent occasions.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							4.1.2 Each time you make a transaction or withdrawal, your Account
							will be debited immediately by the amount of such transaction or
							withdrawal, plus any applicable fee and any other amount that we
							may deduct from your Account under these Terms and Conditions. The
							Usable Balance on your Account will be reduced accordingly.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							4.2 Fees and Charges
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							4.2.1 All fees and charges that relate to the use of your Card and
							Account are detailed in the Fees Section. All applicable fees and
							charges (unless otherwise specified) will be deducted from your
							Usable Balance.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							4.2.2 Unless otherwise specified in these Terms and Conditions,
							all Account fees and charges as listed will be deducted from your
							Default Wallet in the first instance. Where your Default Wallet
							contains insufficient funds to complete such deductions, to
							satisfy the payment of such Account fees and charges, the
							transaction may be declined. In the event that the transaction
							does proceed, CashCare may deduct the fees or charges from your
							other CashCare accounts or offset the sum due from any subsequent
							funds loaded onto your Account.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							4.3 International ATM Withdrawal Fees
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							4.3.1 Notwithstanding anything contained in clauses 4.2.1 or
							4.2.2, fees in respect of international ATM withdrawals made using
							your Card will be deducted in the manner set out below:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) For ATM withdrawals in a Supported Currency, the
								international ATM withdrawal fee will be deducted from the
								Currency Wallet containing such Supported Currency.{" "}
							</li>
							<li>
								(b) For ATM withdrawal in an Unsupported Currency, the
								international ATM withdrawal fee amount will be deducted from
								your Default Wallet in the relevant currency contained in your
								Default Wallet.
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							4.3.2 Please note that: (i) some ATM operators may impose an
							operator fee which will be charged in addition to the total
							transaction amount (along with CashCare’s imposed fees) and be
							deducted from your Usable Balance and (ii) not all ATM operators
							advise you of their ATM operator fees before you withdraw money.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							5. Managing your Account
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.1 Balance enquiry
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							5.1.1 Your available balance is visible in your Currency Wallets
							in your Account when you log in to your CashCare Dashboard, or by
							contacting Customer Support.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.1.2 You can also check your balance through an ATM. Note that
							where you have more than one Currency Wallet an ATM balance
							enquiry will show the aggregate Usable Balance of all funds in
							your Currency Wallets, displayed in the local currency of the ATM.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.1.3 Please note that some ATM operators may charge a fee for ATM
							balance enquiries, which will be deducted from your Usable
							Balance. Please also refer to the Fees Section for relevant
							charges in respect of balance enquiries on your Account through an
							ATM.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.2 Negative Balance
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							If your available balance drops to a Negative Balance, as a result
							of a transaction you have authorized, the resulting Negative
							Balance immediately becomes a debt payable by you to the Issuer.
							We retain the right to recover this debt. If the Card Fund becomes
							overdrawn (Negative Balance), CashCare has the right to suspend or
							block any subsequent transactions and offset the Negative Balance
							from any balance from your other CashCare group accounts or
							subsequent funds loaded into your Account.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.3 Transferring funds between Currency Wallets.
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You may transfer funds loaded onto your Account from one Currency
							Wallet to another Currency Wallet on your own accord.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.4 About Currency Transactions
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							5.4.1 Supported Currency Transactions: When you withdraw money
							from an ATM, make a purchase, or where an ATM operator charges you
							an ATM Operator Fee, in each case in a Supported Currency that you
							have loaded on your Account, the transaction amount including any
							fees payable will be deducted from the relevant Currency Wallet
							holding such Supported Currency. Where you have insufficient funds
							in that Currency Wallet, the transaction will not be successful.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.4.2 A transaction cannot be funded from multiple Currency
							Wallets.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.4.3 Unsupported Currency Transactions: When you withdraw money
							from an ATM, make a purchase, or where an ATM operator charges you
							an ATM operator fee, in each case in an Unsupported Currency,
							funds from your Default Wallet will be debited and converted to
							the Unsupported Currency at the applicable conversion rate. If
							your Default Wallet has no funds, or insufficient funds, then the
							transaction will be unsuccessful.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.5 Errors and transaction disputes
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							5.5.1 You should retain all vouchers and transaction records,
							transaction advice and receipts issued by merchants and ATMs, and
							check all transaction details and entries by reviewing your
							transaction records and history (which may be accessed on your
							CashCare Dashboard or via Customer Support) on a regular basis. We
							accept no responsibility for your failure to regularly review,
							reconcile and verify the correctness of your transaction history
							and details nor your failure to promptly report to us any
							discrepancy, errors, omissions or unauthorized transactions in
							relation thereto
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.2 Details in the entries of your transaction history reflected
							on your CashCare Dashboard are presumed to be true and correct
							unless you notify us in writing of any disputes or inaccuracies
							thereon within two (2) weeks from the time of transaction.
						</p>
						<p className="ml-5 text-base text-clr-gray font-bold">
							Disputes relating to errors
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.3 If you believe an accounting error has occurred with regard
							to the Usable Balance in your Account, or your transaction
							history, you must notify and report such errors to us as soon as
							possible, in any case within two (2) weeks from the date of the
							disputed transaction by contacting our Customer Support team.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.4 You are required to provide your proof of transaction along
							with as much relevant information surrounding such disputed
							transaction as possible. Once we have received that information,
							we will investigate such errors and acknowledge receipt of the
							same in writing within five (5) business days. Where we do not
							acknowledge receipt, it shall be deemed that we have not received
							the information.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.5 Unless you have not complied with our request for
							information or there are exceptional circumstances impeding our
							investigations (in which case we will write to you to let you
							know), we will complete the transaction investigations (in most
							cases) within forty five (45) business days of acknowledging the
							receipt of the information from you and notify you of the outcome.
							Any decision made by CashCare in the resolution of such dispute
							shall also be final and in the sole discretion of CashCare.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.6 If we determine any transaction error or dispute to be
							attributable to our fault or the fault of our service providers
							(including the Payment Processor), we will take steps to rectify
							such errors and make the appropriate refunds to your Card and/or
							Account where necessary. However, if on reasonable grounds we
							decide that any such errors of defaults are not attributable
							whether directly or indirectly to us, the Card Issuer or our
							service providers, we will notify you of our decision in writing
							and shall not be obliged to take any further action. Unless a
							transaction or recording error is due directly to our fault, we
							shall have no liability to you.
						</p>
						<p className="ml-5 text-base text-clr-gray font-bold">
							Disputes regarding non-delivery or unauthorized transactions
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.7 Please note that you cannot stop or reverse a payment on any
							transaction after it has been authorized or completed. Where you
							would like to receive a refund after conducting a transaction you
							should contact the relevant merchant directly with your request.
							When a merchant decides to process your request for a refund, it
							must issue a valid refund voucher to CashCare in order for
							CashCare to make a refund to you. CashCare can only credit your
							Account with the refund after we have received such a voucher from
							the merchant.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.8 Any dispute you have with a merchant over goods or services
							procured using your Card or Account must be addressed directly to
							the merchant from whom such goods or services were provided.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.5.9 We may (but are not obliged to), in our sole and absolute
							discretion, file a formal dispute with a merchant on your behalf
							over a transaction for which you believe you are entitled for a
							refund, or which is otherwise unauthorized, provided that you duly
							comply with our requests for information (including provision of
							proof of transaction) and make available to us all relevant
							documentation. Provided that the dispute is resolved by the
							merchant in your favor, in accordance with the rules of the
							applicable card network, we as the issuer of the Card will credit
							your Account with the disputed amount.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.6 Refunds and/or Reversals
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Any refunds, payment rebates or reversals made by a merchant to
							you in connection with a transaction previously made in a
							Supported Currency, will be credited to your Currency Wallet
							holding that particular Supported Currency. If at the time during
							or after the rebate, refund or payment reversal is processed, you
							no longer have the applicable Currency Wallet and you already have
							the maximum number of Currency Wallets allowed, we will return the
							equivalent amount to your Default Wallet after applying the
							Conversion Rate applicable at the time of the rebate, refund or
							reversal. Rebates, refunds or reversals made in an Unsupported
							Currency will be converted at the applicable conversion rate to
							USDR (ROOT) and returned to your Default Wallet. We will also
							refund any currency conversion fee charged on your original
							transaction, and we will not charge another currency conversion
							fee on the refunded amount. Note that amounts returned for
							Unsupported Currencies and currency conversion fees may differ
							from the amount deducted from your Account at the time of the
							original transaction due to exchange rate fluctuations.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.7 Limitations on use of your Account
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							5.7.1 When using your Card with certain merchants (such as hotels
							and car rentals), the merchant may obtain an authorisation or
							approval to debit your Account for an amount up to 20% more than
							the anticipated total bill to cover additional items such as
							incidentals or to ensure adequate funds are available. The entire
							amount of the authorisation or approval will not be deducted from
							your Usable Balance until the authorisation or approval is
							confirmed. Only the amount actually spent or authorized will be
							finally deducted from your Usable Balance after the authorisation
							or approval is confirmed.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.7.2 You may not use your Card or Account for the following
							purposes:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) making purchases from merchants who do not accept the Card;
							</li>
							<li>
								(b) engaging in any transactions:
								<ul className="ml-6 text-clr-gray">
									<li>(i) which violate any Applicable Laws; </li>
									<li>(ii) involve Prohibited Articles; </li>
									<li>
										(iii) in, or with any persons in any Prohibited Country;{" "}
									</li>
									<li>
										(iv) involve the sales of products or services identified by
										government agencies to have a high likelihood of being
										fraudulent; or
									</li>
									<li>
										(v) are part of or connected to a money transfer mechanism
										to transfer funds to a third party which does not relate to
										an underlying transaction of goods and services.
									</li>
								</ul>
							</li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							5.8 Transaction, load limits and Fees Section
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							To see the latest applicable load limits and schedule of
							applicable fees click here. We reserve the right to revise,
							update, and/or change the limits at our discretion.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							5.8.1 Replacement You may request a new Card or replace an old or
							faulty Card at any time by:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>(i) contacting Customer Support; </li>
							<li>
								(ii) making a Card request via the CashCare Dashboard, or{" "}
							</li>
							<li>
								Card replacements are subject to the Card Replacement Fee.{" "}
							</li>
						</ul>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							6. Expiry, Closing, Termination and Balance Return
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							6.1 Card Expiry
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							6.1.1 Upon activation and until the expiry date shown on your
							Card, your Card may be used to access your Account for the purpose
							of making purchases and withdrawals at ATMs. You may close your
							Account at any time prior to your Card expiration by contacting
							Customer Support.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							6.1.2 Your Card cannot be used at merchants or ATMs after the
							expiry date. Should we be unable to contact you based on our
							records of your contact information six (6) months or more after
							the expiry date of your Card, you acknowledge and agree that we
							may hold your Usable Balance in accordance with Applicable Laws.
							Your Usable Balance will be held in your Default Wallet, with
							amounts in any other Currency Wallets converted at the then
							applicable Conversion Rate.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							6.2 Closing your Account
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							At your option, you may choose to close your Account and obtain a
							refund of your Usable Balance. Upon closure of your Account, you
							may be asked to destroy or return the Card to us. The Card linked
							to your Account will nevertheless be automatically canceled by us.
							Please note that you may not close your account during an ongoing
							dispute, investigation, or pending authorisation.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							6.3 Termination of these Terms and Conditions
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							6.3.1 You acknowledge that we may restrict, withhold or suspend
							the use of your Account and/or Card or terminate these Terms and
							Conditions immediately, without prior notice under any of the
							following circumstances:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								a) your use of the Card and/or Account has, or we reasonably
								believe it has, breached any Applicable Laws or these Terms and
								Conditions;
							</li>
							<li>
								b) we suspect any illegal or unauthorized use of your Account or
								Card;
							</li>
							<li>
								c) you gave us, or we reasonably suspect that you have given to
								us false or inaccurate information (including KYC Information);
							</li>
							<li>
								d) (in the case of a suspension) where necessary for relocating,
								replacing, maintaining, updating or repairing the software or
								hardware facilities of CashCare or the Card Issuer;
							</li>
							<li>
								e) where the Card and Account functions cannot be operated
								normally by CashCare or Card Issuer due to a Force Majeure
								Event, or due to acts or omissions of third parties (including
								service providers of CashCare and/or Card Issuer) which are not
								otherwise caused (whether directly or indirectly) by CashCare
								and/or Card Issuer;
							</li>
							<li>
								f) we believe that your Account has been or is likely to be
								misused or compromised;
							</li>
							<li>
								g) where the continued use of your Account or the Card may cause
								CashCare or Card Issuer to be in breach of any Applicable Laws
								(including, without limitation, laws relating to anti-money
								laundering and combating the financing of terrorism);
							</li>
							<li>
								h) you no longer satisfy the eligibility requirements set out in
								Clause 2.1; or
							</li>
							<li>
								i) we reasonably believe that your Card, Account, PIN, or
								CashCare Dashboard is being used in a way that may cause,
								whether directly or indirectly, losses to you or us.
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							Without limiting the foregoing, in the event of a suspension of
							your use of your Card and the corresponding Account, you agree
							that we may hold your Usable Balance pending the outcome of any
							investigations in connection with the provision of our services,
							and that we may also disclose information relating to your breach
							or suspected breach to such law enforcement or other authorities
							as we may deem necessary. You acknowledge that our decision to
							take certain actions, including termination and limiting access to
							your Card and/or Account, may be based on confidential criteria
							that are essential to our management of risk, the security of all
							our customers and the integrity of our system. You agree that we
							are under no obligation to disclose the details of our risk
							management or security procedures to you.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							6.3.2 Without prejudice to Clause 6.3.1 above, you agree that we
							may close your Account and terminate these Terms and Conditions
							without reason by giving you at least thirty (30) days’ prior
							notice.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							6.3.3 You may also terminate your Card (and the Account) by giving
							us notice thereof in writing sent via email to our Customer
							Support team or by requesting for such termination via the
							CashCare Dashboard. Upon termination of your Card, you must (where
							possible) cut the relevant Card in half through the chip and
							magnetic stripe and cease all usage of the Card. You will pay such
							fees as we may from time to time specify with respect to such
							termination.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							6.3.4 Where we exercise our right of termination under Clause
							6.3.1, you will be entitled to a refund of any Usable Balance on
							your Account in accordance with these Terms and Conditions. The
							User agrees that Clauses 4.2, 5.5, 5.6, 5.8, 6, 7, 8 and 9, as
							well as any other terms which by their nature should survive,
							shall survive the termination of these Terms and Conditions. 
						</p>
						<p className="ml-5 text-base text-clr-gray">
							6.4 Return of Usable Balance If you would like to obtain a
							complete refund of the total amount of Usable Balance left in your
							Account at any time, please contact Customer Support at
							support@cashcare.co.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							7. Unauthorized Transactions
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							7.1 Unauthorized Card use, loss and/or theft.
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							To block and/or suspend your Account you must promptly notify us
							via Customer Support or log on to your CashCare Dashboard if you
							believe any of the following has occurred:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>(a) your Account is hacked, misused or compromised</li>
							<li>(b) any of your Cards is lost or stolen; </li>
							<li>
								(c) any of your Cards is damaged or not working properly;{" "}
							</li>
							<li>(d) any of your Cards is retained by an ATM; or</li>
							<li>
								(e) the security of your PIN or password are compromised in any
								way.
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							Upon our receipt of your notification of any of the events above,
							we will suspend your Card and/or Account and prevent any further
							transactions from being made using your lost, stolen or
							compromised Card and/or Account, until appropriate measures are
							taken to replace your Card and/or restore security to your
							Account.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							You will not be liable for any unauthorized transactions made
							using your Card or Account after we have been notified of the
							loss/theft or compromise of your Card or Account in accordance
							with this clause after you have blocked the use of the Card or
							Account (as applicable). However, you shall be liable for all
							transactions carried out before we are notified of such loss/theft
							or before you block the use of your Card or Account, even if such
							transactions were carried out without your authorisation.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							8. Indemnities and Limitations of Liability
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							8.1 Indemnity
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You shall fully indemnify and hold CashCare, and each of its
							officers, directors, employees, partners and agents
							(“Indemnitees”) harmless from and against any and all damages,
							awards, expenses, losses, claims, actions, liabilities, penalties,
							costs and/or demands (including statutory liability and liability
							to third parties, economic loss, accounting fees, and court and
							legal costs assessed on a solicitor-client basis), suffered or
							incurred by any of the Indemnitees arising whether directly or
							indirectly from your:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) act, omission, negligence, wilful default, mistake,
								misconduct, dishonesty or fraud (including any theft or loss of
								your Card and/or Account, or any fraud or misuse in relation
								thereto);
							</li>
							<li>(b) breach of these Terms and Conditions;</li>
							<li>
								(c) breach of any Applicable Laws, or any contractual or
								fiduciary obligations;{" "}
							</li>
							<li>
								(d) access and use of the CashCare Dashboard, your Card and/or
								Account (or any access and use referable to your PIN and
								passwords);
							</li>
							<li>
								(e) payment and/or ATM cash withdrawal instructions, and/or our
								acting in good faith and taking or refusing to take action based
								thereon; and
							</li>
							<li>(f) violation of any rights of any person or entity.</li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							8.2 No warranty
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Your Card(s), the corresponding Accounts, and the CashCare
							Dashboard are provided to you “as is”, “as available”, without
							warranty of any kind, whether express or implied (including any
							implied warranty of merchantability or satisfactory quality,
							fitness for a particular purpose, compliance with any description,
							non-infringement, or any implied warranty arising from course of
							performance, course of dealing, usage of trade or otherwise, all
							of which are expressly disclaimed). Without limiting the
							foregoing, we do not warrant:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) that the our services provided to you under these Terms and
								Conditions will be error-free, virus-free or free from other
								invasive or damaging code, or that defects therein will be
								corrected;
							</li>
							<li>
								(b) that the operation of the our services (any information
								transmitted by you thereby) will be uninterrupted, continuous,
								or secure;
							</li>
							<li>
								(c) that any information we provide to you on the CashCare
								Dashboard will be correct, accurate, complete, reliable or fit
								for purpose; and/or
							</li>
							<li>
								(d) that the use of your Card(s), the corresponding Accounts
								and/or that the CashCare Dashboard will not give rise to any
								liability at law or under contract.
							</li>
						</ul>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							8.3 CashCare’s liability
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							8.3.1 To the maximum extent permitted by law, CashCare and its
							Indemnitees expressly disclaim and exclude any and all INDIRECT,
							INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE loss or
							damage (including loss of business, revenue, profits, use of data,
							or other economic benefits) which may be suffered by you (or any
							person claiming under or through you) in connection with or
							however arising from the your access or use of the CashCare
							Dashboard, your Card and the corresponding Account, whether or not
							(a) the same arises in contract, tort (including negligence)
							howsoever; (b) such loss or damage is foreseeable and/or (c)
							CashCare and its Indemnitees have received prior notification of
							the possibility thereof.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							8.3.2 To the maximum extent permitted by law, CashCare hereby
							disclaims all liability to you in connection with or arising from
							your use of or inability to use your Card(s), the corresponding
							Accounts and/or the CashCare Dashboard, which are incurred as a
							result or in connection with any of the following:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) failed, missed, delayed, or fraudulent transactions, payment
								transmission errors, or any other issues related to payment or
								the processing, loading and/or transfer of money between you and
								the Card Issuer as the loading channel;{" "}
							</li>
							<li>
								(b) the Card Issuer’s failure to convey any notice, instructions
								or communications to us or other third parties on your behalf,
								accurately, in a timely manner or at all;
							</li>
							<li>
								(c) any unauthorized access to, disclosure of, wrongful or
								fraudulent use of your Card(s), Account, CashCare Dashboard,
								personal data, PIN and password to the extent not attributable
								to any acts or omissions on part of CashCare;
							</li>
							<li>
								(d) any error, negligence, wilful default, misconduct, fraud,
								act, omission, breach or failure of, or contravention of
								Applicable Laws by, any other party (including you and the Card
								Issuer);
							</li>
							<li>
								(e) for any claim that arises from our acting reasonably in
								accordance with your instructions;
							</li>
							<li>
								(f) for any loss suffered by you or any third party due to
								matters outside our control (including, but not limited to,
								non-delivery of mail, machine failure, system failure, strikes,
								failure of an ATM or communication linkage failure);
							</li>
							<li>
								(g) for any losses which arise where you have acted negligently
								or have acted fraudulently, either alone or together with any
								other person(s); or{" "}
							</li>
							<li>
								(h) any losses incurred by you as a result of your breach of
								Applicable Laws or these Terms and Conditions.{" "}
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							8.3.3 In the event that CashCare is liable for damages despite the
							foregoing provisions, you agree that (a) no action, whatever its
							form, may be brought by you more than two (2) months after the
							date of the first event giving rise to your claim, and (ii) that
							our maximum aggregate liability to you under any and all causes of
							action (including contract and tort), shall not exceed $1,000 (for
							the avoidance of doubt, this does not affect your rights to claim
							for a return of Usable Balance under Clause 6.4).
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							9. General
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.1 Severability
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							If any provision of these Terms and Conditions is found to be
							invalid, the invalidity of such provision shall not affect the
							validity of the remaining provisions of these Terms and
							Conditions, which shall remain in full force and effect.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.2 Assignment
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Your rights and obligations under these Terms and Conditions are
							personal to you and you shall not transfer, assign, novate or
							sub-contract them to any third party without our prior written
							consent. We reserve the right to transfer, assign, novate,
							sub-contract or otherwise deal with any of our rights or
							obligations under these Terms at any time, and you hereby give
							consent to, and shall do all things necessary to facilitate such
							transfer, assignment, novation or dealing by us.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.3 Waiver
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Failure or neglect by us to enforce at any time any of the
							provisions in these Terms and Conditions shall not be construed or
							deemed to be a waiver of our rights hereunder, nor in any way
							affect the validity of the whole or any part of these Terms or
							prejudice our right to take subsequent action.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.4 Force Majeure
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							We shall not be liable for any failure or delay in the performance
							of our obligations under these Terms and Conditions, or any
							unavailability thereof that is due, in whole or in part, directly
							or indirectly to any Force Majeure events which is beyond our
							reasonable control.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.5 Compliance with Laws
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							You shall comply with all Applicable Laws relating to the use of
							the Card(s), the corresponding Accounts and the CashCare
							Dashboard.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.6 Third Party Rights
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Save for the Indemnitees, a person or entity who is not a party to
							this Agreement shall have no right under the Contracts (Rights of
							Third Parties) Act to enforce any term of this Agreement.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.7 Amendment
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							We may amend, supplement or replace these Terms and Conditions,
							including any fees and charges associated with the use of your
							Card and/or Account, from time to time by giving you reasonable
							prior notice in the manner set forth in Clause 9.8 below. We will
							also publish such amendments or changes to these Terms and
							Conditions on the Website and your CashCare Dashboard. The amended
							Terms and Conditions shall come into force on the day such Terms
							and Conditions are posted on the Website and CashCare Dashboard.
							Any use of your Card and/or the corresponding Account thereafter
							by you shall constitute an acceptance of the revised Terms and
							Conditions. If you do not agree with these Terms and Conditions,
							you should immediately terminate your Account
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.8 Notice or Communications
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							Subject to any Applicable Laws, you agree that we or our agents
							may provide written notices or other communications to you under
							or in connection with these Terms and Conditions in any of the
							following methods, and such notice will be deemed received by you
							as specified below:
						</p>
						<ul className="ml-14 text-clr-gray">
							<li>
								(a) by post to your residential or postal address last known to
								us: 2 business days after our date of posting;
							</li>
							<li>(b) by hand: on delivery;</li>
							<li>
								(c) by electronic communication to your last email address: on
								the day such email is transmitted if no notice of delivery
								failure is received;
							</li>
							<li>
								(d) by electronic communication via posting on your CashCare
								Dashboard for retrieval by you (with the email or text message
								advising you of this and of the general nature of the
								information, and giving you the ability to readily retrieve the
								information electronically): within 24 hours from our posting of
								such electronic communication.{" "}
							</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							In addition to the communications channels set out in these Terms
							and Conditions, we and our agents may give you a notice or other
							communication by using any method allowed or required under
							Applicable Laws. If Applicable Laws require us or our agents to
							use a particular method of communication or providing notice, such
							method will be used.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.9 Entire Agreement
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							These Terms and Conditions set forth the entire understanding
							between you and us with respect to the subject matter hereof and
							supersedes all proposals or other communications, oral or written,
							relating to this subject matter.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.10 Dispute Resolution
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							CashCare's goal is to learn about and address your concerns
							arising from and in connection with our issued Card(s) and the
							corresponding Accounts, and should we be unable to do so to your
							satisfaction, to provide you with a neutral and cost effective
							means of resolving the dispute quickly. If you would like to raise
							any complaint or dispute our services, such dispute may be
							reported to Customer Support.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							If we have not been able to resolve your complaint regarding your
							Card to your satisfaction, you may contact the FINMA of
							Switzerland.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							9.11 Governing Law
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							These Terms and Conditions shall be governed in all respects by
							the laws of Switzerland. You irrevocably agree to submit to the
							exclusive jurisdiction of the courts of Switzerland for the
							purpose of litigating or settling all such claims or disputes
							under these Terms and Conditions and related to your Account
							and/or Card.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Terms;
