import React from "react";

// custom
import { Navbar, Sidebar } from "../Shared";
import hero from "../../assets/imgs/about_hero.gif";

function AboutHero() {
	return (
		<div className="flex flex-col items-center justify-center w-full bg-about_bg h-auto">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<div className="flex md:flex-row flex-col items-center justify-center py-40 px-4">
					<div className="flex flex-col space-y-6 md:items-start md:justify-start items-center justify-center">
						<h1 className="md:text-7xl text-5xl font-jihoBold uppercase pb-6">
							CashCare
						</h1>
						<p className="text-xl text-clr-gray md:w-3/5 w-4/5 font-normal">
							CashCare, powered by UIFC, is an all-inclusive financial
							services platform backed by the power of cryptocurrency based
							capabilities.{" "}
						</p>

						<button className="bg-clr-dark-purple rounded-md py-3 px-8 text-white font-semibold text-lg">
							Apply Now
						</button>
					</div>
					<img src={hero} alt="about hero" className="md:w-1/2 w-8/10" />
				</div>
			</div>
		</div>
	);
}

export default AboutHero;
