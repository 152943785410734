import React, { useEffect } from "react";

// custom
import { Navbar, Sidebar } from "../../components/Shared";
import WalletContent from "../../components/Wallet/WalletContent";

function WalletPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center h-screen w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<WalletContent />
			</div>
		</div>
	);
}

export default WalletPage;
