import React, { useEffect } from "react";

//custom
import VerifyForm from "../../components/Verify/VerifyForm";

function VerifyPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-clr-background bg-center S-900:h-screen h-auto w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative items-center justify-center h-screen">
				<VerifyForm />
			</div>
		</div>
	);
}

export default VerifyPage;
