import React, { useEffect, useState } from "react";

// lib
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Modal } from "antd";

// custom
import { useAuthContext } from "../../contexts/auth_context";
import { axios_auth } from "../../utils/axios";
import { api_url } from "../../utils/constants";
import { classNames, getLocalStorage } from "../../utils/helpers";

// regex
const key_REGEX = /^[a-zA-Z0-9]{64,66}$/;

function JoinContent() {
	const { user, getUser } = useAuthContext();
	// console.log(user.user.wallet_address);
	const [copiedKey, setCopiedKey] = useState(false);
	const [wallet, setWallet] = useState({
		address: "",
		balance: "0.0",
		UIFCBalance: 0,
	});

	const [open, setOpen] = useState(false);
	const [pKey, setPKey] = useState("");
	const [pKeyValid, setPKeyValid] = useState(false);
	const [pKeyFocus, setPKeyfocus] = useState(false);
	const [loading, setLoading] = useState(false);

	//set private key
	const handlePassword = (e: any) => {
		const val = e.currentTarget.value;
		setPKey(val);
		//console.log("pkey", val);
	};
	//check the validation for private key
	useEffect(() => {
		setPKeyValid(key_REGEX.test(pKey));
	}, [pKey]);

	//fake
	const handleOk = () => {};
	//fake
	const handleCancel = () => {
		setOpen(false);
	};

	// copy pKey
	const handleCopy = () => {
		const key: any = document.getElementById("key");
		navigator.clipboard.writeText(key.innerHTML);
		setCopiedKey(true);
		toast.success("key is copied", {
			position: "top-left",
		});
	};

	// join directly with saved pKey
	const handlejoin = async () => {
		const hashedKey = Cookies.get("key");
		//console.log("key", hashedKey);
		if (hashedKey !== undefined) {
			setLoading(true);
			const bytes = CryptoJS.AES.decrypt(
				hashedKey,
				process.env.REACT_APP_KEY_HASH
			);
			const decrypted = bytes.toString(CryptoJS.enc.Utf8);

			axios_auth(Cookies.get("token"))
				.post(api_url + "/join-network", { privateKey: decrypted })
				.then((response: any) => {
					setLoading(false);
					if (response.data.status) {
						toast.success(response.data.message, {
							position: "top-left",
						});
					} else {
						Cookies.remove("key");
						toast.error(response.data.message, {
							position: "top-left",
						});
					}
				})
				.catch((error) => {
					setLoading(false);
					Cookies.remove("key");
					toast.error(error.message, {
						position: "top-left",
					});
				});
		} else {
			setOpen(true);
		}
	};

	// join after add pKey
	const handleSendTransaction = async () => {
		setLoading(true);
		axios_auth(Cookies.get("token"))
			.post(api_url + "/join-network", { privateKey: pKey })
			.then((response: any) => {
				if (response.data.status) {
					const encrypted = CryptoJS.AES.encrypt(
						pKey,
						process.env.REACT_APP_KEY_HASH
					).toString();
					Cookies.set("key", encrypted, {
						expires: 7,
						path: "/",
					});
					toast.success(response.data.message, {
						position: "top-left",
					});
				} else {
					Cookies.remove("key");
					toast.error(response.data.message, {
						position: "top-left",
					});
				}
				setLoading(false);
				setOpen(false);
			})
			.catch((error) => {
				Cookies.remove("key");
				toast.error(error.message, {
					position: "top-left",
				});
				setLoading(false);
				setOpen(false);
			});
	};

	// get balance
	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/wallet/balance")
			.then((response: any) => {
				if (response.data.status) {
					setWallet(response.data.data.walletDetails);
				} else {
					toast.error(response.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, [user.user.wallet_address]);

	// get User
	// useEffect(() => {
	// 	getUser();
	// }, []);

	return (
		<div className="flex S-900:flex-row flex-col items-center justify-center S-900:my-10 my-8">
			<div className="py-12 rounded-3xl bg-clr-light-blue flex flex-col space-y-12  S-900:w-1/2 w-10/12 ">
				{/* head */}
				<div className="flex flex-col items-center text-center space-y-3">
					<h1 className="uppercase text-4xl font-semibold text-clr-dark-purple font-jihoBold">
						My Wallet
					</h1>
					<p className="text-lg text-clr-gray font-normal text-center w-[85%]">
						you can check your wallet details from here, you will find your
						balance UIFC{" "}
						<span className="text-clr-dark-purple font-semibold">UIFC</span>{" "}
						and <span className="text-clr-dark-purple font-semibold">BNB</span>.
					</p>
				</div>
				{/* key */}
				<div className="flex flex-col items-center text-center mx-auto space-y-1 w-[85%]">
					<div className="flex items-center justify-between w-[85%] mx-auto">
						<span className="text-clr-dark-purple text-xl font-semibold">
							Public Address
						</span>
						<p
							className="flex flex-row items-center justify-center space-x-1 text-lg cursor-pointer"
							onClick={handleCopy}
						>
							<MdContentCopy className="text-clr-dark-purple" />
							<span className="text-clr-gray">Copy</span>
						</p>
					</div>

					<p
						id="key"
						className="py-4 px-6 bg-white rounded-xl shadow-whiteBox w-[85%] mx-auto break-all text-clr-gray text-xl font-semibold"
					>
						{user.user.wallet_address}
					</p>
					<p className="text-base text-clr-gray font-normal text-center mx-auto w-[85%]">
						This is your{" "}
						<span className="font-semibold">Public Wallet Address</span>, use it
						to transfer UIFC from your existing wallet.
					</p>
				</div>
				{/* balance */}
				<div className="flex flex-col  space-y-8 w-[70%] mx-auto">
					<div className="flex flex-col items-start justify-start">
						<h2 className="text-3xl text-clr-dark-purple font-bold font-jihoBold text-left">
							Balance
						</h2>
						<p className="text-clr-gray text-base">
							Detail about your CashCare Balance
						</p>
					</div>
					<div className="flex items-start justify-between w-[60%]">
						<div className="flex flex-col space-y-1 text-clr-dark-purple font-bold ">
							<span className="text-xl">ToL</span>
							<span className="text-xl">{wallet.UIFCBalance}</span>
						</div>
						<div className="flex flex-col space-y-1 text-clr-dark-purple font-bold ">
							<span className="text-xl">BNB</span>
							<span className="text-xl">{wallet.balance}</span>
						</div>
					</div>
					{/* <div className="flex flex-col space-y-4 items-center justify-center py-2">
						<button
							onClick={() =>
								getLocalStorage("auth").user?.is_joined
									? console.log("you're member")
									: handlejoin()
							}
							className="w-full rounded-xl bg-clr-dark-purple uppercase py-2 text-xl font-bold text-white border border-clr-dark-purple hover:bg-transparent hover:text-clr-dark-purple transition-all ease-in-out duration-75"
						>
							{getLocalStorage("auth").user?.is_joined
								? "You're member"
								: "Join The Wait List"}
							{loading && Cookies.get("key") !== undefined && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 ml-3 text-white font-semibold animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
						</button>
					</div> */}
				</div>
				{/* pKey modal */}
				<Modal
					title={
						<div className="flex flex-row space-x-1 items-center">
							<h1 className="text-xl font-bold text-clr-dark-purple">
								Transction Process
							</h1>
						</div>
					}
					style={{ top: 20 }}
					footer={<div></div>}
					open={open}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					{/* private */}
					<div className="flex flex-col space-y-2 py-5">
						<label
							htmlFor="p_key"
							className="text-clr-gray text-lg font-medium"
						>
							Private Key
						</label>
						<input
							id="p_key"
							name="p_key"
							type={"password"}
							autoComplete="off"
							aria-invalid={pKeyValid ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setPKeyfocus(true)}
							onBlur={() => setPKeyfocus(false)}
							value={pKey}
							onChange={(e) => handlePassword(e)}
							className={classNames(
								"px-4 py-2 rounded-xl full font-bold text-clr-gray-dark text-base outline-none",
								pKeyValid ? "shadow shadow-green-400" : "shadow border",
								!pKeyValid && pKeyFocus
									? "shadow shadow-red-400"
									: "shadow-input"
							)}
						/>
					</div>

					<div className="flex flex-col items-center justify-center">
						<button
							disabled={!pKeyValid}
							className={classNames(
								!pKeyValid ? "opacity-50" : "",
								"py-1 px-4 text-white font-semibold border-2 border-clr-dark-purple bg-clr-dark-purple disabled:bg-clr-dark-purple rounded-lg text-lg hover:bg-transparent hover:text-clr-dark-purple cursor-pointer disabled:cursor-default"
							)}
							onClick={handleSendTransaction}
						>
							Pay
							{loading && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 ml-3 text-white font-semibold animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
						</button>
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default JoinContent;
