import React from "react";

// lib
import { useNavigate } from "react-router-dom";

// custom
import hero_nav from "../../assets/imgs/CashCare.png";
import Hero_img from "../../assets/imgs/hero_img.svg";

function Hero() {
	const navigate = useNavigate();

	return (
		<div className="flex flex-col sm:space-y-8 space-y-4 items-center justify-center relative pb-44">
			<img src={hero_nav} alt="logo" className="w-40 mt-10" />
			<h1 className="text-black sm:text-5xl text-4xl font-semibold flex flex-col w-[70%] space-y-4 items-center justify-center text-center font-jihoBold capitalize">
				<span>The next-gen of finance</span>
				<span>Now at your fingertips!</span>
			</h1>
			<p className="flex flex-col sm:space-y-8 space-y-5 items-center justify-center text-center text-lg font-medium sm:w-[35%] w-smallWidth mx-auto">
				<span>
					CashCare is redefining the future of financial ownership and
					empowerment with comprehensive features and services for you or your
					business. Join the new financial wave.
				</span>
				<span>
					Join the new financial wave– join the CashCare waitlist and invite
					others today!{" "}
				</span>
			</p>
			<div className="flex sm:flex-row flex-col sm:space-x-5 space-x-0 sm:space-y-0  space-y-5 relative py-5">
				<div
					className="relative cursor-pointer group"
					onClick={() => navigate("/user/join")}
				>
					<button className="px-4 py-3 rounded-md bg-clr-dark-purple text-white text-base font-semibold">
						Join the Waitlist
					</button>
					<div className="border-2 border-clr-dark-blue py-[1.4rem] px-[4.6rem] rounded-md absolute top-2 left-2 group-hover:top-0 group-hover:left-0 transition-all ease-in-out duration-300"></div>
				</div>

				<div
					className="relative cursor-pointer group"
					onClick={() => navigate("/login")}
				>
					<button className="px-4 py-3 rounded-md bg-clr-dark-purple text-white text-base font-semibold">
						Invite Friends
					</button>
					<div className="border-2 border-clr-dark-blue py-[1.4rem] px-[4.0rem] rounded-md absolute top-2 left-2 group-hover:top-0 group-hover:left-0 transition-all ease-in-out duration-300"></div>
				</div>
			</div>
			<img
				alt="hero_img"
				src={Hero_img}
				className="absolute xl:w-[350px] w-[250px] left-0 xl:top-52 top-80 S-900:block hidden"
			/>
		</div>
	);
}

export default Hero;
