import React, { useState } from "react";

// lib
import { Modal } from "antd";
import {
	FaFacebookMessenger,
	FaFacebookSquare,
	FaTelegram,
	FaWhatsapp,
} from "react-icons/fa";
import { MdContentCopy, MdEmail } from "react-icons/md";
import { toast } from "react-toastify";
import {
	EmailShareButton,
	FacebookShareButton,
	WhatsappShareButton,
	TelegramShareButton,
	FacebookMessengerShareButton,
} from "react-share";

// custom
import invite_img from "../../assets/imgs/invite_img.svg";
import { shared_url } from "../../utils/constants";
import { useAuthContext } from "../../contexts/auth_context";

function Invitation() {
	const { user, getUser } = useAuthContext();
	const [open, setOpen] = useState(false);

	const text = "please go to this website, then register and earn rewards ";
	const base_url = shared_url + "/register?code=" + user.user.referral_code;

	//fake
	const handleOk = () => {};
	//fake
	const handleCancel = () => {
		setOpen(false);
	};

	// copy shared url
	const handleCopy = () => {
		navigator.clipboard.writeText(
			shared_url + "/register?code=" + user.user.referral_code
		);
		toast.success("Invitation Link is copied", {
			position: "top-left",
		});
	};

	return (
		<div className="flex flex-col items-center justify-center space-y-8 py-8 ">
			<h1 className="text-center text-clr-dark-gray font-normal sm:text-4xl text-3xl mx-auto">
				You will get alot of benefits!
			</h1>
			<span className="sm:text-xl text-lg mx-auto text-clr-gray text-center font-normal">
				Utilizing blockchain to transform transactions with UIFC financial
				services.
			</span>
			<img src={invite_img} alt="cards" />
			<span className="sm:text-xl text-lg mx-auto text-clr-gray text-center font-normal">
				Join us and and invite others to take advantage of conventional
				financial services Send your Invitations Now!
			</span>
			<div
				className="relative cursor-pointer group"
				onClick={() => setOpen(true)}
			>
				<button className="px-4 py-3 rounded-md bg-clr-dark-purple text-white text-base font-semibold">
					Invite Friends
				</button>
				<div className="border-2 border-clr-dark-blue py-[1.4rem] px-[3.95rem] rounded-md absolute top-2 left-2 group-hover:top-0 group-hover:left-0 transition-all ease-in-out duration-300"></div>
			</div>
			<Modal
				title={
					<div className="flex flex-row space-x-1 items-center">
						<h1 className="text-2xl font-jihoBold font-bold text-clr-dark-purple">
							Share
						</h1>
					</div>
				}
				style={{ top: 20 }}
				footer={<div></div>}
				open={open}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<div className="flex flex-wrap  items-center justify-start py-3">
					{/* copy */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox cursor-pointer">
						<MdContentCopy className="w-6 h-6 text-clr-dark-purple" />
						<span
							// url={shared_url}
							onClick={handleCopy}
							className="text-base text-clr-gray font-normal"
						>
							Copy
						</span>
					</div>
					{/* email */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox">
						<MdEmail className="w-6 h-6 text-gray-600" />
						<EmailShareButton
							url={`${text} ${base_url}`}
							className="text-xl text-clr-gray font-medium"
						>
							Email
						</EmailShareButton>
					</div>
					{/* facebook */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox">
						<FaFacebookSquare className="w-6 h-6 text-[#3b5998]" />
						<FacebookShareButton
							url={`${text} ${base_url}`}
							className="text-xl text-clr-gray font-medium"
						>
							Facebook
						</FacebookShareButton>
					</div>
					{/* whatsapp */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox">
						<FaWhatsapp className="w-6 h-6 text-green-600" />
						<WhatsappShareButton
							url={`${text} ${base_url}`}
							className="text-xl text-clr-gray font-medium"
						>
							WhatsApp
						</WhatsappShareButton>
					</div>
					{/* messenger */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox">
						<FaFacebookMessenger className="w-6 h-6 text-[#006AFF]" />
						<FacebookMessengerShareButton
							url={`${text} ${base_url}`}
							appId=""
							className="text-xl text-clr-gray font-medium"
						>
							Messenger
						</FacebookMessengerShareButton>
					</div>
					{/* telegram */}
					<div className="flex flex-row rounded border border-clr-gray px-4 py-2 space-x-1 mx-3 my-2 hover:shadow-whiteBox">
						<FaTelegram className="w-6 h-6 text-[#0088cc]" />
						<TelegramShareButton
							url={`${text} ${base_url}`}
							className="text-xl text-clr-gray font-medium"
						>
							Telegram
						</TelegramShareButton>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default Invitation;
