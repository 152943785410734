import React, { useState, useRef } from "react";

// libs
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

// custom
import Nav_logo from "../../assets/imgs/nav_logo.svg";
import ActiveItem from "../../assets/imgs/activeItem.svg";
import { useMainContext } from "../../contexts/main_context";
import { useAuthContext } from "../../contexts/auth_context";
import UseRemoveInputFocus from "../../hooks/useRemoveInputFocus";
import { navbarList } from "../../utils/constants";
import { classNames } from "../../utils/helpers";

function Navbar() {
	const { user, logout } = useAuthContext();
	const { openSidebar } = useMainContext();
	const [openUserList, setOpenUserList] = useState(false);

	let location = useLocation();
	const navigate = useNavigate();

	const profileListRef: any = useRef();
	UseRemoveInputFocus(profileListRef, () => setOpenUserList(false));

	return (
		<div className="w-full flex items-center justify-between h-20">
			<div className="">
				<Link to="/">
					<img alt="logo" src={Nav_logo} />
				</Link>
			</div>
			<div className=" flex-col items-center justify-center S-900:flex hidden">
				<ul className="flex flex-row space-x-8 font-Manrope text-lg font-bold items-center justify-center">
					{navbarList.map((item: any) => {
						return (
							<li className="relative" key={item.id}>
								<Link to={item.href}>{item.text}</Link>
								{location.pathname === item.href && (
									<img
										src={ActiveItem}
										className={classNames(
											location.pathname == "/contact" ? "-left-6" : "-left-2",
											"absolute  top-[0.9rem] w-28 h-5"
										)}
										alt="active"
									/>
								)}
							</li>
						);
					})}
				</ul>
			</div>
			<div className="S-900:block hidden">
				{user.isLoggedin ? (
					<ul className="flex flex-row items-center space-x-2 text-lg font-bold">
						<li className="relative">
							<span
								onClick={() => setOpenUserList(!openUserList)}
								className="border-2 border-transparent hover:border-clr-dark-purple px-4 py-[10px] rounded-md text-clr-dark-purple capitalize cursor-pointer"
							>
								{`${user.user.first_name} ${user.user.last_name}`}
							</span>
							{openUserList && (
								<ul
									ref={profileListRef}
									className="absolute top-12 z-20 bg-clr-dark-blue rounded shadow-otpShadow w-[170px] flex flex-col items-center justify-center py-4"
								>
									<li className="font-jihoBold text-xl py-2 border-b border-clr-gray w-[90%] mx-auto text-center text-clr-gray hover:text-clr-dark-purple hover:bg-clr-light-purple rounded-t">
										<Link
											to="/user/profile"
											className=""
											onClick={() => setOpenUserList(false)}
										>
											Profile
										</Link>
									</li>
									<li className="font-jihoBold text-xl py-2 border-b border-clr-gray w-[90%] mx-auto text-center text-clr-gray hover:text-clr-dark-purple hover:bg-clr-light-purple">
										<Link
											to="/user/join"
											className=""
											onClick={() => setOpenUserList(false)}
										>
											Wallet
										</Link>
									</li>
									<li className="font-jihoBold text-xl py-2 mx-auto text-center text-clr-gray hover:text-clr-dark-purple hover:bg-clr-light-purple rounded-b">
										<Link
											to="/user/delete"
											className=""
											onClick={() => setOpenUserList(false)}
										>
											Delete Account
										</Link>
									</li>
								</ul>
							)}
						</li>
						<li>
							<button
								onClick={logout}
								className="bg-clr-dark-purple border-2 border-clr-dark-purple text-white px-4 py-2 rounded-md hover:bg-transparent hover:text-clr-dark-purple transition-all ease-in-out"
							>
								Sign Out
							</button>
						</li>
					</ul>
				) : (
					<ul className="flex flex-row space-x-2 text-lg font-bold">
						<li>
							<Link
								to="/login"
								className="border-2 border-transparent hover:border-clr-dark-purple px-4 py-2 rounded-md text-clr-dark-purple"
							>
								Sign In
							</Link>
						</li>
						<li>
							<Link
								to="/register"
								className="bg-clr-dark-purple border-2 border-clr-dark-purple text-white px-4 py-2 rounded-md hover:bg-transparent hover:text-clr-dark-purple transition-all ease-in-out"
							>
								Register
							</Link>
						</li>
					</ul>
				)}
			</div>
			<button
				type="button"
				onClick={openSidebar}
				id="nav-toggle"
				className="bg-transparent border-transparent text-clr-dark-purple cursor-pointer S-900:hidden"
			>
				<FaBars className="text-3xl" />
			</button>
		</div>
	);
}

export default Navbar;
