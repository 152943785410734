import React, { useEffect } from "react";
import { Navbar, Sidebar } from "../../components/Shared";

function FAQs() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<div className="flex flex-col items-center justify-center my-4 mt-10 space-y-8 w-full">
					<h1 className="text-5xl text-clr-dark-purple font-jihoBold py-5 font-bold">
						FAQs
					</h1>
					<div className="flex flex-col space-y-8 pb-8">
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								1 - What do I use my CashCare prepaid card for?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								CashCare prepaid cards are designed exclusively for you for
								everyday lifestyle purchases via crypto, like groceries,
								shopping and online shopping. If you’re a business owner or on
								the way to becoming one, you will be eligible for the CashCare
								Business prepaid card, which will avail you with more
								business-friendly features and benefits to help you succeed.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								2 - What benefits do I get with the prepaid card?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								CashCare has teamed up with numerous brands worldwide from
								beauty, apparel, electronics, food and beverages, and more, to
								bring you exclusive discounts via cash back rewards when you
								swipe your CashCare prepaid card for every purchase.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								3 - How many cards are available on CashCare?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								The CashCare Personal card and the CashCare Business card are
								available, for now! We have more exciting features and card
								products coming to you in the future. Keep visiting our website
								to check back for regular updates
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								4 - What else can I do with CashCare?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								CashCare is a total financial solutions provider enabled through
								crypto, enhanced with the security and power of blockchain
								integration. Apart from prepaid cards, the app will also let you
								transfer and receive funds instantly, and maintain a balance in
								your wallet integrated right through the CashCare app.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								5 - What is the fee structure for CashCare cards?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								You will pay an onboarding or sign-up fee when registering for
								the CashCare Personal and Business cards. An annual fee will
								also be applicable for every CashCare card held by you. These
								fees are subject to change and will be reflected on{" "}
								<a href="https://cashcare.co/" className="font-bold">
									www.cashcare.co.
								</a>
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								6 - Does CashCare carry out a verification process?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								We conduct a KYC (Know Your Customer) procedure for every
								personal user and business owner/intermediary. This will include
								entering your personal information, business information, valid
								contact details, government issued identity card, and a liveness
								check.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-full">
							<h2 className="text-clr-dark-blue font-semibold text-xl">
								7 - How can I know my eligibility for the CashCare Business
								card?
							</h2>
							<p className="mt-2 ml-5 w-[80%]  text-clr-gray">
								The CashCare Business card is exclusively for small-business
								owners as well as large-scale businesses. As with personal
								users, as a business owner or intermediary, you will conduct a
								business verification process with the required business
								documents which will be listed in detail when you begin the
								registration process.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FAQs;
