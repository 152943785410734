import React, { useState, useEffect } from "react";

// lib
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

// custom
import LoginImg from "../../assets/imgs/login_img.gif";
import { classNames } from "../../utils/helpers";
import { axios } from "../../utils/axios";

//regex
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;

function ContactForm() {
	const [loading, setLoading] = useState(false);

	const [phoneVal, setPhoneVal] = useState("");
	const handelphone = (value: string) => {
		const name = "phone";
		setPhoneVal(value);

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const [formData, setFormData] = useState({
		email: "",
		phone: "",
		subject: "",
		content: "",
	});

	const [formValidate, setFormValidate] = useState({
		email: false,
		phone: false,
		subject: false,
		content: false,
	});

	const [formFocus, setFormFocus] = useState({
		email: false,
		phone: false,
		subject: false,
		content: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log(name, value);
		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		setFormValidate({
			...formValidate,
			email: EMAIL_REGEX.test(formData.email),
			phone: PHONE_REGEX.test(formData.phone),
			subject: formData.subject !== "",
			content: formData.content !== "",
		});
	}, [formData]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);

		if (!formValidate.subject) {
			toast.error(`there was an error. please fill in sublect.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.content) {
			toast.error(`there was an error. please fill in content.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.email) {
			toast.error(`there was an error. please fill in email.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.phone) {
			toast.error(`there was an error. please fill in phone.`, {
				position: "top-left",
			});
			setLoading(false);
		} else {
			console.log("form - valid", formData);
			axios
				.post("/contact-us", formData)
				.then((response: any) => {
					toast.success(response.data.message, {
						position: "top-left",
					});
					setLoading(false);
				})
				.catch((error: any) => {
					toast.error(error.message, {
						position: "top-left",
					});
					setLoading(false);
				});
		}
	};

	return (
		<div className="flex S-900:flex-row flex-col items-center justify-center my-16">
			<div className="py-20 rounded-3xl bg-clr-light-blue flex flex-col space-y-3 items-center justify-center S-900:w-1/2 w-10/12 ">
				<img src={LoginImg} alt="login image" className="w-14 h-14" />
				<h1 className="uppercase text-4xl font-semibold font-jihoBold">
					Contact Us
				</h1>
				<p className="text-clr-gray text-base">
					Any questions? Drop an inquiry here and we'll get back to you soon!
				</p>
				<div className="w-9/12 flex flex-col space-y-6 pt-4">
					{/* input - Email */}
					<div className="w-full relative">
						<input
							type="text"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.email ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, email: true })}
							onBlur={() => setFormFocus({ ...formFocus, email: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
								formFocus.email && !formValidate.email
									? "border-red-600"
									: formFocus.email && formValidate.email
									? "border-green-600"
									: "border-white"
							)}
							placeholder="Email *"
						/>
						<div
							id="uidnote"
							className={
								formFocus.email && formData.email && !formValidate.email
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Email not valid
						</div>
					</div>
					{/* input - Phone */}
					<div className="w-full relative">
						<PhoneInput
							country={"ae"}
							value={formData.phone}
							onChange={(phone) => handelphone(phone)}
							onFocus={() => setFormFocus({ ...formFocus, phone: true })}
							onBlur={() => setFormFocus({ ...formFocus, phone: false })}
							aria-invalid={formValidate.phone ? "false" : "true"}
							autoFormat={false}
							inputProps={{
								name: "phone",
								required: true,
								autoFocus: true,
							}}
							inputStyle={{
								background: "white",
								color: "#3F3192",
								fontSize: "18px",
								padding: "26px 48px",
								width: "100%",
								border: "none",
							}}
							buttonClass="countryClassBtn"
							buttonStyle={{
								background: "transparent",
								border: "none",
								fontSize: "18px",
								textAlign: "center",
								padding: "10px 10px",
							}}
							dropdownClass="countryClassDropdown"
							dropdownStyle={{
								background: "#4ABCDF",
								border: "1px solid #4ABCDF",
								borderRadius: "10px",
								padding: "10px 00px",
								display: "flex",
								flexDirection: "column",
								alignItems: "start",
							}}
						/>
						<div
							id="uidnote"
							className={
								formFocus.phone && formData.phone && !formValidate.phone
									? "font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1"
									: "hidden"
							}
						>
							Phone Number is not valid
						</div>
					</div>
					{/* input - subject */}
					<div className="w-full relative">
						<input
							type="text"
							id="subject"
							name="subject"
							value={formData.subject}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.subject ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, subject: true })}
							onBlur={() => setFormFocus({ ...formFocus, subject: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
								formFocus.subject && !formValidate.subject
									? "border-red-600"
									: formFocus.subject && formValidate.subject
									? "border-green-600"
									: "border-white"
							)}
							placeholder="Subject *"
						/>
						<div
							id="uidnote"
							className={
								formFocus.subject && formData.subject && !formValidate.subject
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Required.
						</div>
					</div>
					{/* input - content */}
					<div className="w-full relative">
						<textarea
							id="content"
							name="content"
							value={formData.content}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.content ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, content: true })}
							onBlur={() => setFormFocus({ ...formFocus, content: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
								formFocus.content && !formValidate.content
									? "border-red-600"
									: formFocus.content && formValidate.content
									? "border-green-600"
									: "border-white"
							)}
							placeholder="Content *"
						/>
						<div
							id="uidnote"
							className={
								formFocus.content && formData.content && !formValidate.content
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Required.
						</div>
					</div>
					{/* btn */}
					<div className="flex flex-row items-center justify-center space-x-4 pb-3">
						<button
							onClick={handleSubmit}
							className="relative bg-clr-dark-purple w-full rounded-lg py-3 text-2xl font-bold text-white uppercase border-2 border-clr-dark-purple hover:bg-transparent hover:text-clr-dark-purple"
						>
							<span className="flex flex-row items-center justify-center space-x-2  text-clr-main-dark tracking-wider font-medium lg:text-lg md:text-base text-sm">
								<span>Submit</span>
								{loading && (
									<svg
										aria-hidden="true"
										role="status"
										className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="#E5E7EB"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentColor"
										/>
									</svg>
								)}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactForm;
