import React from "react";

function MyRewards() {
	return (
		<div className="flex flex-col items-center justify-center space-y-14 my-14">
			<h1 className="text-center text-clr-dark-gray font-normal sm:text-4xl text-3xl mx-auto">
				You will get alot of benefits!
			</h1>
			<p className="text-center text-clr-dark-purple border border-clr-dark-purple rounded-lg py-5 sm:px-36 px-20 mx-auto bg-clr-light-purple text-4xl font-semibold">
				00.00 Points
			</p>
			<span className="sm:text-xl text-lg mx-auto text-clr-gray text-center font-normal">
				When you use our cards to make purchases on the websites of our
				partners, you can earn rewards up to 15%.
			</span>
		</div>
	);
}

export default MyRewards;
