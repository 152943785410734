import React, { useState } from "react";
import MyRewards from "./MyRewards";
import Invitation from "./Invitation";
import { classNames } from "../../utils/helpers";

function ProfileContent() {
	const [currentTab, setCurrentTab] = useState(2);
	return (
		<div className="flex S-900:flex-row flex-col items-center justify-center S-900:my-0 my-4">
			<div className="py-10 rounded-3xl flex flex-col space-y-12 items-center justify-center S-900:w-1/2 w-10/12 ">
				<div className="flex sm:flex-row flex-col">
					<span
						className={classNames(
							currentTab == 1
								? "border-b-2 border-clr-dark-purple text-clr-dark-purple font-bold"
								: "border-b border-clr-gray-sec text-clr-gray-sec font-medium",
							"py-2 sm:px-8 px-4 sm:text-4xl text-3xl cursor-pointer"
						)}
						onClick={() => setCurrentTab(1)}
					>
						Get Rewards
					</span>
					<span
						className={classNames(
							currentTab == 2
								? "border-b-2 border-clr-dark-purple text-clr-dark-purple font-bold"
								: "border-b border-clr-gray-sec text-clr-gray-sec font-medium",
							"py-2 sm:px-8 px-4 sm:text-4xl text-3xl cursor-pointer"
						)}
						onClick={() => setCurrentTab(2)}
					>
						Invite Friends
					</span>
				</div>
				<div className="flex my-10">
					{currentTab === 1 && <MyRewards />}

					{currentTab === 2 && <Invitation />}
				</div>
			</div>
		</div>
	);
}

export default ProfileContent;
