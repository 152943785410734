import React from "react";

// custom
import Logo from "../../assets/imgs/nav_logo.svg";
import { Link } from "react-router-dom";
import { footerLinks, socialList } from "../../utils/constants";

function Footer() {
	return (
		<div className="bg-clr-dark-blue bg-gradient-to-tr from-[#E8F7FD] to-clr-dark-blue w-full pt-10 pb-2 shadow-md rounded-t-lg mt-14">
			<div className="max-w-max-custom w-smallWidth mx-auto px-2">
				{/* row */}
				<div className="flex S-900:flex-row flex-col items-center justify-center S-900:space-x-52 space-x-0 S-900:space-y-0 space-y-10  py-10">
					<div className="flex flex-col S-900:w-1/3 w-4/5 items-start justify-start space-y-4">
						<Link to={"/"}>
							<img src={Logo} alt="logo" className="w-44 h-12" />
						</Link>

						<p className="text-xl font-medium">
							CashCare brings the future of financial services and utilities for
							you or your business. Download CashCare via Google Play and iOS
							and join us now.
						</p>
						<ul className="flex flex-row space-x-3">
							{socialList.map((item: any) => {
								return (
									<li
										key={item.id}
										className="bg-clr-dark-purple p-3 text-clr-light-purple text-lg rounded-full opacity-80 hover:opacity-100"
									>
										<Link to={item.href} className="w-full h-full">
											<span>{item.icon}</span>
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="grid c_sm:grid-cols-3 c_xs:grid-cols-2 grid-cols-1 gap-5 S-900:w-2/3 w-4/5">
						{/* col-links */}
						{footerLinks.map((item: any) => {
							return (
								<div key={item.id} className="flex flex-col space-y-2">
									<h3 className="text-clr-dark-purple text-xl font-bold">
										{item.name}
									</h3>
									<ul className="text-lg font-normal">
										{item.options.map((itemLink: any) => {
											return (
												<li
													key={itemLink.id}
													className="text-clr-dark-gray hover:text-clr-dark-purple"
												>
													<Link to={itemLink.href} className="w-full h-full">
														{itemLink.text}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					</div>
				</div>
				{/* row */}
				<div className="flex flex-col items-center justify-center text-center">
					<p className="font-Manrope text-lg font-normal text-clr-dark-gray">
						2023 Rights reserved
					</p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
