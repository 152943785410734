export const classNames = (...classes : any) => {
    return classes.filter(Boolean).join(' ')
}

export const getLocalStorage = (variableName : string) =>{
  let requiredVar : any = localStorage.getItem(variableName);
  switch (variableName) {
      case 'auth':
        return requiredVar ? JSON.parse(requiredVar) : {user: {} , isLoggedin : false };
      default:
        return {}
    }
}
