import React, { useEffect } from "react";
import { Navbar, Sidebar } from "../../components/Shared";

function BlogPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center h-screen w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<div className="flex flex-col items-center justify-center my-4">
					BlogPage
				</div>
			</div>
		</div>
	);
}

export default BlogPage;
