import React from "react";
import logo from "./logo.svg";
import "./App.css";

// lib
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	About,
	Blog,
	Contact,
	Error,
	FAQs,
	Home,
	Privacy,
	Terms,
	UserAgreement,
} from "./pages/Static";
import { Login, Register, Verify } from "./pages/Auth";

// custom
import { useAuthContext } from "./contexts/auth_context";
import { DeleteAccount, Join, Profile, Wallet } from "./pages/User";
import { Footer } from "./components/Shared";

function App() {
	const { user } = useAuthContext();

	return (
		<Router>
			<ToastContainer />
			<Routes>
				{/* base pages */}
				<Route path="/" element={<Home />}>
					{" "}
				</Route>
				<Route path="/about" element={<About />}>
					{" "}
				</Route>
				<Route path="/contact" element={<Contact />}>
					{" "}
				</Route>
				<Route path="/blog" element={<Blog />}>
					{" "}
				</Route>
				<Route path="/terms" element={<Terms />}>
					{" "}
				</Route>
				<Route path="/privacy" element={<Privacy />}>
					{" "}
				</Route>
				<Route path="/agreement" element={<UserAgreement />}>
					{" "}
				</Route>
				<Route path="/faq" element={<FAQs />}>
					{" "}
				</Route>

				{/* auth */}
				<Route
					path="/register"
					element={
						!user.isLoggedin ? (
							<Register />
						) : (
							<Navigate to="/user/profile"></Navigate>
						)
					}
				>
					{" "}
				</Route>
				<Route
					path="/login"
					element={
						!user.isLoggedin ? (
							<Login />
						) : (
							<Navigate to="/user/profile"></Navigate>
						)
					}
				>
					{" "}
				</Route>
				<Route
					path="/verify"
					element={
						// <Verify />
						!user.isLoggedin ? (
							<Verify />
						) : user.keySaved ? (
							<Navigate to="/user/profile"></Navigate>
						) : (
							<Navigate to="/user/wallet"></Navigate>
						)
					}
				>
					{" "}
				</Route>

				{/* profile */}
				<Route
					path="/user/profile"
					element={
						user.isLoggedin ? <Profile /> : <Navigate to="/login"></Navigate>
					}
				>
					{" "}
				</Route>

				{/* wallet */}
				<Route
					path="/user/wallet"
					element={
						user.isLoggedin ? <Wallet /> : <Navigate to="/login"></Navigate>
					}
				>
					{" "}
				</Route>

				{/* wallet */}
				<Route
					path="/user/delete"
					element={
						user.isLoggedin ? (
							<DeleteAccount />
						) : (
							<Navigate to="/login"></Navigate>
						)
					}
				>
					{" "}
				</Route>

				{/* join */}
				<Route
					path="/user/join"
					element={
						user.isLoggedin ? <Join /> : <Navigate to="/login"></Navigate>
					}
				>
					{" "}
				</Route>

				{/* error - unkown pages */}
				<Route path="/*" element={<Error />}>
					{" "}
				</Route>
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
