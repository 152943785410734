import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-clr-background bg-center S-900:h-screen h-auto w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative items-center justify-center h-screen">
				<div className="bg-white flex flex-col items-center justify-center py-14 sm:px-14 px-4 rounded-lg shadow-otpShadow space-y-5 mx-auto">
					<h1 className="text-8xl font-bold text-clr-dark-purple font-jihoBold">
						404
					</h1>
					<p className="text-2xl font-semibold text-clr-dark-gray">
						Sorry, the page you tried cannot be found
					</p>
					<Link
						to="/"
						className="hover:uppercase capitalize transition-all ease-in-out delay-500 px-6 py-3 bg-clr-dark-blue text-clr-background font-semibold  rounded-lg shadow-clr-dark-gray"
					>
						back home
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ErrorPage;
