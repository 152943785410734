import React, { useEffect } from "react";

//custom
import { Footer, Navbar, Sidebar } from "../../components/Shared";
import Hero from "../../components/Home/Hero";
import Hero_img from "../../assets/imgs/hero_img.svg";

function HomePage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<Hero />
			</div>
		</div>
	);
}

export default HomePage;
