import React from "react";

// lib
import { motion } from "framer-motion";

// custom
import bussinessCard from "../../assets/imgs/New-cc-Card-Dark.png";
import personalCard from "../../assets/imgs/New-cc-Card.png";

function AboutContent() {
	return (
		<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
			<div className="flex flex-col space-y-8 items-center justify-center my-4 py-14">
				<h1 className="text-7xl font-jihoBold text-clr-dark-purple uppercase ">
					About
				</h1>
				<div className="w-[60%] mx-auto text-center text-clr-dark-gray flex flex-col space-y-3">
					<p className="mx-auto  font-normal text-lg">
						With the world transitioning towards centralized platforms for
						seamless transactions for lifestyle and business needs, CashCare
						introduces an all-in-one utility to carry out efficient and instant
						financial transactions exclusively via UIFC, or a range of other
						cryptocurrencies available on the platform.
					</p>
					<p className="mx-auto font-normal text-lg">
						CashCare brings about total autonomy and purpose, allowing you to
						use your UIFC or other tokens as a traditional financial medium,
						sans regional limitations and latencies.
					</p>
					<p className="mx-auto font-normal text-lg">
						Experience the next-gen of financing with our prepaid cards for
						users and businesses, complete with exclusive cash back rewards with
						select merchants worldwide.
					</p>
				</div>
				<div className="flex flex-col items-center justify-center py-20 space-y-5">
					<h1 className="text-5xl text-clr-dark-gray font-jihoBold uppercase text-center">
						A CARD FOR YOUR NEXT MOVE
					</h1>
					<p className="text-xl text-clr-dark-gray font-medium pb-20 w-[60%] mx-auto text-center">
						Transfer, top-up and you’re good to go! Live the seamless ToL
						experience with CashCare cards for everyday and business use.
					</p>
					<div className="flex flex-col items-center justify-center space-y-32 w-[80%] mx-auto">
						<div className="group flex S-900:flex-row flex-col items-center S-900:justify-end justify-center S-900:space-x-40 space-x-0 relative w-full c_xl:mr-32 mr-0">
							<img
								src={personalCard}
								alt="personal card"
								className="lg:w-[400px] S-900:w-[300px] sm:w-1/2 w-[250px] rounded-xl transform -rotate-[10deg] group-hover:-rotate-[20deg] shadow-xl S-900:absolute c_2xl:left-[20%] xl:left-[12%] lg:left-[2%] S-900:left-[8%] group-hover:left-10 transition-all ease-linear duration-500"
							/>
							<div className="flex flex-col space-y-5 text-center items-center justify-center font-jihoBold S-900:w-1/2 w-full  S-900:bg-gradient-to-l bg-gradient-to-t from-clr-baby-purple to-transparent rounded-lg py-16 cursor-pointer">
								<h1 className="text-4xl font-bold text-clr-dark-purple">
									Personal Card
								</h1>
								<p className="text-clr-dark-gray text-lg text-center mx-auto w-[70%]">
									The CashCare Personal card allows you to use your crypto as a
									daily lifestyle accessory. It’s as easy as topping up your
									card with UIFC to swipe it away for every purchase
									in-store, or for online purchases. Flaunt your CashCare card
									and live the true futuristic experience with just a hint of
									luxury.{" "}
								</p>
							</div>
						</div>

						<div className="group flex S-900:flex-row flex-col items-center S-900:justify-start justify-center S-900:space-x-32 space-x-0 relative w-full c_xl:ml-32 ml-0">
							<img
								src={bussinessCard}
								alt="personal card"
								className="lg:w-[400px] S-900:w-[300px] sm:w-1/2 w-[250px] rounded-xl transform  rotate-[10deg] group-hover:rotate-[20deg] shadow-xl S-900:absolute c_2xl:right-[8%] xl:right-[0%] lg:-right-[11%] S-900:-right-[5%]  group-hover:right-0 transition-all ease-linear duration-500"
							/>
							<div className="flex flex-col space-y-5 text-center items-center justify-center font-jihoBold S-900:w-1/2 w-full  S-900:bg-gradient-to-r bg-gradient-to-t from-clr-light-blue to-transparent rounded-lg py-16 cursor-pointer">
								<h1 className="text-4xl font-bold text-clr-dark-purple">
									Business Card
								</h1>
								<p className="text-clr-dark-gray text-xl text-center mx-auto w-[70%]">
									Empowering business owners and aspiring startups, the CashCare
									Business card is designed with intricacy to improve your
									business operations and fast forward your business’s growth
									with enhanced financial and business friendly essentials
									across bookkeeping, productivity or account management tools.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutContent;
