import React, { useState, useEffect } from "react";

// lib
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

//custom
import { useAuthContext } from "../../contexts/auth_context";
import { classNames } from "../../utils/helpers";

//regex
const USER_REGEX = /^[A-z][A-z0-9-_ ]{2,23}$/;
const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;

function RegisterForm() {
	const { register } = useAuthContext();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(false);
	const [errors, setErrors] = useState(null);
	const [isValid, setIsValid] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	let location = useLocation();
	const pathSearchArr = location.search?.split("=");
	const [currentCode, setCurrentCode] = useState(
		pathSearchArr.length < 2 ? "" : pathSearchArr[1]
	);

	//console.log("test", currentCode);

	const [phoneVal, setPhoneVal] = useState("");
	const handelphone = (value: string) => {
		const name = "phone";
		setPhoneVal(value);

		setFormData({
			...formData,
			[name]: value,
		});
		setErrors(null);
	};

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		password: "",
		password_confirmation: "",
		checkAgree: false,
		referral_code: currentCode,
	});

	const [formValidate, setFormValidate] = useState({
		first_name: false,
		last_name: false,
		email: false,
		phone: false,
		password: false,
		password_confirmation: false,
		checkAgree: false,
	});

	const [formFocus, setFormFocus] = useState({
		first_name: false,
		last_name: false,
		email: false,
		phone: false,
		password: false,
		password_confirmation: false,
		checkAgree: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log(name, value);
		if (name === "checkAgree") {
			//console.log(name, e.target.checked);
			setFormData({ ...formData, checkAgree: e.target.checked });
			setFormValidate({ ...formValidate, checkAgree: e.target.checked });
		} else {
			setFormData({ ...formData, [name]: value });
		}
		setErrors(null);
	};

	useEffect(() => {
		setFormValidate({
			...formValidate,
			first_name: USER_REGEX.test(formData.first_name),
			last_name: USER_REGEX.test(formData.last_name),
			email: EMAIL_REGEX.test(formData.email),
			password: PASSWORD_REGEX.test(formData.password),
			password_confirmation:
				formData.password === formData.password_confirmation,
			phone: PHONE_REGEX.test(formData.phone),
		});

		setIsValid(
			formValidate.first_name &&
				formValidate.last_name &&
				formValidate.email &&
				formValidate.phone &&
				formValidate.password &&
				formValidate.password_confirmation &&
				formValidate.checkAgree
		);
	}, [formData, formValidate.password_confirmation, formData.checkAgree]);

	useEffect(() => {
		if (errors) {
			toast.error(errors, {
				position: "top-left",
			});
			console.log("errors", errors);
		}
	}, [errors]);

	useEffect(() => {
		if (status) {
			navigate("/verify");
		}
	}, [status]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setStatus(false);
		setErrors(null);
		setLoading(true);

		// if(formData.referral_code === ""){
		// 	setFormData({...formData,referral_code:"ROOT"})
		// }
		//console.log("form", isValid, formValidate);
		//console.log("formData", formData);
		if (!formValidate.first_name) {
			toast.error(`there was an error. please fill in first name.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.last_name) {
			toast.error(`there was an error. please fill in last name.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.email) {
			toast.error(`there was an error. please fill in email.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.phone) {
			toast.error(`there was an error. please fill in phone.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.password) {
			toast.error(
				`there was an error. Your Password weak must have @-#-$-% /Capital letter/numbers`,
				{
					position: "top-left",
				}
			);
			setLoading(false);
		} else if (!formValidate.password_confirmation) {
			toast.error(
				`there was an error. password Must match the first password input field.`,
				{
					position: "top-left",
				}
			);
			setLoading(false);
		} else if (!formValidate.checkAgree) {
			toast.error(`there was an error. please check the terms.`, {
				position: "top-left",
			});
			setLoading(false);
		} else {
			//console.log("form - valid", formData);
			register({
				...formData,
				setStatus,
				setErrors,
				setLoading,
			});
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="py-8 rounded-3xl bg-clr-light-blue flex flex-col space-y-3 items-center justify-center S-900:w-1/2 w-10/12">
			<h1 className="uppercase text-4xl font-semibold font-jihoBold">
				register
			</h1>
			<p className="text-clr-gray text-base">Log in or create an account.</p>
			<div className="w-9/12 flex flex-col space-y-6 pt-4">
				{/* input - first name */}
				<div className="w-full relative">
					<input
						type="text"
						id="first_name"
						name="first_name"
						value={formData.first_name}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={formValidate.first_name ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setFormFocus({ ...formFocus, first_name: true })}
						onBlur={() => setFormFocus({ ...formFocus, first_name: false })}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
							formFocus.first_name && !formValidate.first_name
								? "border-red-600"
								: formFocus.first_name && formValidate.first_name
								? "border-green-600"
								: "border-white"
						)}
						placeholder="First Name *"
					/>
					<div
						id="uidnote"
						className={
							formFocus.first_name &&
							formData.first_name &&
							!formValidate.first_name
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						3 to 24 characters.
					</div>
				</div>
				{/* input - last name */}
				<div className="w-full relative">
					<input
						type="text"
						id="last_name"
						name="last_name"
						value={formData.last_name}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={formValidate.last_name ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setFormFocus({ ...formFocus, last_name: true })}
						onBlur={() => setFormFocus({ ...formFocus, last_name: false })}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
							formFocus.last_name && !formValidate.last_name
								? "border-red-600"
								: formFocus.last_name && formValidate.last_name
								? "border-green-600"
								: "border-white"
						)}
						placeholder="Last Name *"
					/>
					<div
						id="uidnote"
						className={
							formFocus.last_name &&
							formData.last_name &&
							!formValidate.last_name
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						3 to 24 characters.
					</div>
				</div>
				{/* input - Email */}
				<div className="w-full relative">
					<input
						type="text"
						id="email"
						name="email"
						value={formData.email}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={formValidate.email ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setFormFocus({ ...formFocus, email: true })}
						onBlur={() => setFormFocus({ ...formFocus, email: false })}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
							formFocus.email && !formValidate.email
								? "border-red-600"
								: formFocus.email && formValidate.email
								? "border-green-600"
								: "border-white"
						)}
						placeholder="Email *"
					/>
					<div
						id="uidnote"
						className={
							formFocus.email && formData.email && !formValidate.email
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Email not valid
					</div>
				</div>
				{/* input - Phone */}
				<div className="w-full relative">
					<PhoneInput
						country={"ae"}
						value={formData.phone}
						onChange={(phone) => handelphone(phone)}
						onFocus={() => setFormFocus({ ...formFocus, phone: true })}
						onBlur={() => setFormFocus({ ...formFocus, phone: false })}
						aria-invalid={formValidate.phone ? "false" : "true"}
						autoFormat={false}
						inputProps={{
							name: "phone",
							required: true,
							autoFocus: true,
						}}
						inputStyle={{
							background: "white",
							color: "#3F3192",
							fontSize: "18px",
							padding: "26px 48px",
							width: "100%",
							border: "none",
						}}
						buttonClass="countryClassBtn"
						buttonStyle={{
							background: "transparent",
							border: "none",
							fontSize: "18px",
							textAlign: "center",
							padding: "10px 10px",
						}}
						dropdownClass="countryClassDropdown"
						dropdownStyle={{
							background: "#4ABCDF",
							border: "1px solid #4ABCDF",
							borderRadius: "10px",
							padding: "10px 00px",
							display: "flex",
							flexDirection: "column",
							alignItems: "start",
						}}
					/>
					<div
						id="uidnote"
						className={
							formFocus.phone && formData.phone && !formValidate.phone
								? "font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1"
								: "hidden"
						}
					>
						Phone Number is not valid
					</div>
				</div>
				{/* input - Password */}
				<div className="w-full relative">
					<input
						type={showPassword ? "text" : "password"}
						id="password"
						name="password"
						value={formData.password}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={formValidate.password ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setFormFocus({ ...formFocus, password: true })}
						onBlur={() => setFormFocus({ ...formFocus, password: false })}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
							formFocus.password && !formValidate.password
								? "border-red-600"
								: formFocus.password && formValidate.password
								? "border-green-600"
								: "border-white"
						)}
						placeholder="Password *"
					/>
					<button
						className="absolute top-4 right-5 text-clr-gray hover:underline text-sm"
						type="button"
						onClick={() => setShowPassword(!showPassword)}
					>
						{showPassword ? "hide" : "show"}
					</button>
					<div
						id="uidnote"
						className={
							formFocus.password && formData.password && !formValidate.password
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Your Password weak must have @-#-$-% /Capital letter/numbers
					</div>
				</div>
				{/* input - Confirm */}
				<div className="w-full relative">
					<input
						type={showConfirmPassword ? "text" : "password"}
						id="password_confirmation"
						name="password_confirmation"
						value={formData.password_confirmation}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={formValidate.password_confirmation ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() =>
							setFormFocus({ ...formFocus, password_confirmation: true })
						}
						onBlur={() =>
							setFormFocus({ ...formFocus, password_confirmation: false })
						}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium",
							formFocus.password_confirmation &&
								!formValidate.password_confirmation
								? "border-red-600"
								: formFocus.password_confirmation &&
								  formValidate.password_confirmation
								? "border-green-600"
								: "border-white"
						)}
						placeholder="Conform Password *"
					/>
					<button
						className="absolute top-4 right-5 text-clr-gray text-sm hover:underline"
						type="button"
						onClick={() => setShowConfirmPassword(!showConfirmPassword)}
					>
						{showConfirmPassword ? "hide" : "show"}
					</button>
					<div
						id="uidnote"
						className={
							formFocus.password_confirmation &&
							formData.password_confirmation &&
							!formValidate.password_confirmation
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Must match the first password input field.
					</div>
				</div>
				{/* input - Referral */}
				<div className="w-full relative">
					<input
						type={"text"}
						id="referral_code"
						name="referral_code"
						value={formData.referral_code}
						onChange={handleInput}
						autoComplete="off"
						// aria-invalid={formValidate.password_confirmation ? "false" : "true"}
						aria-describedby="uidnote"
						// onFocus={() =>
						// 	setFormFocus({ ...formFocus, password_confirmation: true })
						// }
						// onBlur={() =>
						// 	setFormFocus({ ...formFocus, password_confirmation: false })
						// }
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-dark-purple bg-white border placeholder:text-black placeholder:text-base placeholder:font-medium"
							// formFocus.password_confirmation &&
							// 	!formValidate.password_confirmation
							// 	? "border-red-600"
							// 	: formFocus.password_confirmation &&
							// 	  formValidate.password_confirmation
							// 	? "border-green-600"
							// 	: "border-white"
						)}
						placeholder="Referral Code"
					/>
					{/* <div
						id="uidnote"
						className={
							formFocus.password_confirmation &&
							formData.password_confirmation &&
							!formValidate.password_confirmation
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Must match the first password input field.
					</div> */}
				</div>
				{/* input - agree */}
				<div className="flex items-center mb-4 col-span-12 relative -top-2">
					<input
						id="checkAgree"
						name="checkAgree"
						onChange={handleInput}
						aria-describedby="checkbox-1"
						type="checkbox"
						className="bg-clr-dark-purple border-clr-dark-purple focus:ring-3 focus:ring-clr-dark-purple ring-clr-dark-purple accent-clr-dark-purple h-4 w-4 rounded"
					/>
					<label
						htmlFor="checkAgree"
						className="text-base ml-1 font-medium text-clr-off-white"
					>
						I agree to the Terms of Use and Privacy Policy
					</label>
				</div>
				{/* btn */}
				<div className="flex flex-row items-center justify-center space-x-4 pb-3">
					<button
						onClick={handleSubmit}
						className="relative bg-clr-dark-purple w-full rounded-lg py-3 text-2xl font-bold text-white uppercase border-2 border-clr-dark-purple hover:bg-transparent hover:text-clr-dark-purple"
					>
						<span className="flex flex-row items-center justify-center space-x-2  text-clr-main-dark tracking-wider font-medium lg:text-lg md:text-base text-sm">
							<span>Register</span>
							{loading && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
						</span>
					</button>
				</div>
			</div>
			<p className="text-lg font-light">
				Already have an account?{" "}
				<Link className="text-clr-dark-purple font-normal" to={"/login"}>
					Log In
				</Link>
			</p>
		</div>
	);
}

export default RegisterForm;
