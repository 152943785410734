import React from "react";

// custom
import RegisterForm from "./RegisterForm";
import RegImg from "../../assets/imgs/reg_img.svg";
import AppleStore from "../../assets/imgs/apple_store.svg";
import GoogleStore from "../../assets/imgs/google_store.svg";

function RegisterContent() {
	return (
		<div className="flex S-900:flex-row flex-col items-center justify-between my-10">
			<div className="flex flex-col space-y-5 items-start justify-start">
				<p className="text-3xl font-semibold font-jihoBold">
					Download our <span className="text-clr-dark-purple">CashCare</span>{" "}
					App.
				</p>
				<div className="flex flex-wrap space-x-3">
					<img src={AppleStore} alt="apple store" />
					<img src={GoogleStore} alt="google store" />
				</div>
				<div className="pt-8">
					<img
						src={RegImg}
						alt="register hero"
						className="lg:w-auto S-900:w-2/3 S-900:block hidden"
					/>
				</div>
			</div>
			<RegisterForm />
		</div>
	);
}

export default RegisterContent;
