import React, { useEffect } from "react";

// custom
import { Navbar, Sidebar } from "../../components/Shared";
import LoginForm from "../../components/Login/LoginForm";

function LoginPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center  w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<LoginForm />
			</div>
		</div>
	);
}

export default LoginPage;
