import React, { useEffect } from "react";
import { Navbar, Sidebar } from "../../components/Shared";

function Privacy() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-center w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative">
				<Navbar />
				<Sidebar />
				<div className="flex flex-col items-center justify-center my-4 mt-10 space-y-8">
					<h1 className="text-5xl text-clr-dark-purple font-jihoBold py-5 font-bold">
						Privacy Policy
					</h1>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							1. General information
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							Phydias SA, located at rue des Pâquis 8 1202 Genève, Switzerland
							(the “Company”, “we”, or “us”) is the operator of any Website
							published by the Company, including, but not limited to,
							cashcare.co (the “Website”).
						</p>
						<p className="ml-5 text-base text-clr-gray">
							As the operator of the Website, we take the protection of your
							user data very seriously. We collect, process, and use your
							personal data in accordance with this privacy policy and in
							compliance with the Swiss Federal Act on Data Protection (“FADP”),
							the Swiss Ordinance to the Federal Act on Data Protection
							(“OFADP”), and the General European Data Protection Regulation
							(“GDPR”),{" "}
							<span className="font-bold">
								which are currently being coordinated and under review as of
								11th August, 2023.
							</span>
						</p>
						<p className="ml-5 text-base text-clr-gray">
							This privacy policy (“Privacy Policy”) will provide you with
							information about the collection, processing and use of your
							personal data when using the Websites.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							In case you provide us with the personal data of third persons
							(such as family members or work colleagues) you should make sure
							that these persons are familiar with this Privacy Policy and you
							should only share their personal data if you have permission to do
							so and ensure that this personal data is correct.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							2. Responsible Person
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							For any matters relating to data protection you may contact
							info@cashcare.co in writing by email.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							3. Data processing in connection with the Websites
						</h2>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							3.1 Visiting our Websites
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							When you visit our Websites, the hosting provider(s) of our
							Websites may automatically collect and store various information
							in server log files that your browser transmits to us. The
							information/data mentioned is neither assigned to specific persons
							nor linked to data from other sources. The following technical
							data may be recorded by us, as usual with every connection with a
							web server, without your intervention, and stored by us until
							automatic deletion after no later than two days:
						</p>
						<ul className="ml-14 text-clr-gray list-disc">
							<li>Transaction records</li>
						</ul>
						<p className="ml-5 text-base text-clr-gray">
							Any collection and processing of this technical data is for the
							purpose of enabling the use of our Websites, continuously ensuring
							system security and stability, optimizing our Websites, and for
							internal statistical purposes. This is our legitimate interest in
							the processing and review of our pending GDPR compliance process.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							Furthermore, the IP addresses may be evaluated, together with
							other data, in case of attacks on the network infrastructure or
							other unauthorized use or misuse of the Websites, for the purpose
							of intelligence and protection, and if appropriate, used in
							criminal proceedings for identification and civil and criminal
							proceedings against the relevant users. This is our legitimate
							interest in the processing and review of our pending GDPR
							compliance process.
						</p>
						<h3 className="text-xl font-semibold text-clr-dark-blue ml-3">
							3.2 Collection of User Data
						</h3>
						<p className="ml-5 text-base text-clr-gray">
							We obtain information about you when you use our website, when you
							contact us via email or a web form or if you register to receive
							one of our regular newsletters. We may collect and process the
							following types of information about you:
						</p>
						<ul className="ml-14 text-clr-gray list-disc">
							<li>
								Identification Information, such as name, email, phone number,
								postal address (for account registration, correspondences
								informing the user of account activity and CashCare
								communications), and/or government-issued identity documents
								such as a valid passport, based on the applicable region;
							</li>
							<li>
								Commercial Information, such as trading activity, order
								activity, deposits, withdrawals, account balances;
							</li>
							<li>
								Financial Information, such as bank account information, payment
								card details, virtual currency accounts, stored value accounts,
								amounts associated with accounts, external account details,
								information on referrals related to you, source of funds and
								related documentation and routing number;
							</li>
							<li>
								Correspondence, such as information that you provide to us in
								correspondence, including account opening and customer support;
							</li>
							<li>
								Audio, Electronic, Visual or Similar Information, such as images
								and videos collected for identity verification;
							</li>
							<li>
								Biometric Information, such as scans of your face geometry
								extracted from identity documents for identity verification in
								accordance with KYC policies;
							</li>
							<li>
								Professional or Employment-related Information, such as job
								title, source of wealth;
							</li>
							<li>
								Institutional Information, such as for institutional customers,
								we may collect additional information, including: institution’s
								legal name, VAT number, TAX number, business registration
								number, Employer Identification Number (“EIN”) or any comparable
								identification number issued by a government, and proof of legal
								existence (which may include articles of incorporation,
								certificate of formation, trade or business license, trust
								instrument, or other comparable legal document) based on the
								applicable region;
							</li>
							<li>
								Sensitive Personal Information, such as government-issued
								identification numbers (which may include driver’s license
								number, valid passport number) based on the applicable region;
							</li>
							<li>
								Marketing and Communications Data including your preferences in
								receiving marketing materials, promotions and/or newsletters
								from us and your communication preferences, feedback or
								complaints which you have communicated to us;
							</li>
							<li>
								Transactional Data such as details about payments to and from
								you, and other details of any transactions you enter into using
								the Services, Site or App.{" "}
							</li>
							<li>
								Technical Data such as operator and carrier data, login data,
								diagnostics data such as crash logs and any other data we
								collect for the purposes of measuring technical diagnostics, and
								other information stored on or available regarding the devices
								you allow us access to when you visit the Site, or use the
								Services or the App.
							</li>
						</ul>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							Information you provide us
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							You may provide us with personal information by filling in forms
							on our website(s) or by corresponding with us by email or
							live-chat. The personal information you provide may include your
							name, email, phone number and public wallet address.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							By providing us with this information, you expressly consent to
							our use of your personal information in accordance with this
							Privacy Policy, solely for the aforementioned purposes. YOUR USER
							DATA WILL NOT BE STORED BY US AND WILL NOT BE COLLECTED, STORED OR
							PROCESSED FOR ANY INTERNAL PURPOSES, OPERATIONAL PURPOSES OR
							MARKETING PURPOSES.
						</p>
						<p className="ml-5 text-base text-clr-gray">
							We review our retention periods for personal information on a
							regular basis. We will hold your personal information on our
							systems for as long as is necessary for the relevant activity
							mentioned above.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							Who has access to your information
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							The user data collected during signing up, signing in, and sending
							the OTP to your email will only be accessible by the database
							administrators for the sole purpose of account authentication
							only. We will take the necessary steps to ensure that your privacy
							rights continue to be protected.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							Your choices and your rights
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							We will not contact you for internal purposes, operational
							purposes or marketing purposes and in doing so, we will obtain
							your prior consent.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							How you can update your information
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							The accuracy of your information is important to us. If you change
							your email address, or any of the other information we hold is
							inaccurate or out of date, please email us at support@cashcare.co.
							You have the right to ask for a copy of the information CashCare
							holds about you.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							Security measures to protect your information
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							When you give us personal information, we take steps to ensure
							that it’s treated securely. While we strive to protect your
							personal information, we cannot guarantee the security of any
							information you transmit to us, and you do so at your own risk.
							When we receive your information for account authentication
							purposes, we make our best effort to ensure its security on our
							systems.
						</p>
					</div>
					{/* section */}
					<div className="flex flex-col items-start justify-start w-[80%] mx-auto space-y-4">
						<h2 className="text-2xl font-semibold mb-6 text-clr-dark-gray">
							Profiling
						</h2>
						<p className="ml-5 text-base text-clr-gray">
							The provisions of this Policy also apply to the request for and
							sending of our newsletter. The newsletter will only be sent after
							a prior request by the user in the so-called “double opt-in
							procedure” (request on our website and confirmation of a
							corresponding request email). This data will not be passed on to
							third parties. The user has the right to withdraw his or her
							consent at any time, by unsubscribing from the newsletter. To do
							so, simply unsubscribe via the link in the newsletter or send an
							email to support@cashcare.co. The withdrawal of consent shall not
							affect the lawfulness of processing based on consent before its
							withdrawal.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Privacy;
