import {
	FaFacebookF,
	FaInstagram,
	FaTelegramPlane,
	FaTwitter,
} from "react-icons/fa";

import Twitter from "../assets/imgs/twitter 2.svg";
export const api_url =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_Back_URL_SERVER
		: process.env.REACT_APP_Back_URL_TEST;

export const shared_url: any =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_SHARED_URL_SERVER
		: process.env.REACT_APP_SHARED_URL_TEST;

export const navbarList = [
	{
		id: "navList_1",
		text: "Home",
		href: "/",
	},
	{
		id: "navList_2",
		text: "About",
		href: "/about",
	},
	{
		id: "navList_3",
		text: "Contact Us",
		href: "/contact",
	},
	{
		id: "navList_4",
		text: "Privacy",
		href: "/privacy",
	},
];

export const navbarAuthList = [
	{
		id: "navAuthList_1",
		text: "Sign In",
		href: "/login",
	},
	{
		id: "navAuthList_2",
		text: "Register",
		href: "/register",
	},
];

export const socialList = [
	{
		id: "socialList_1",
		icon: <FaFacebookF />,
		href: "https://www.facebook.com/cashcareofficial",
	},
	{
		id: "socialList_2",
		icon: <FaInstagram />,
		href: "https://www.instagram.com/cashcare.co/",
	},
	{
		id: "socialList_3",
		icon: <img src={Twitter} alt="twitter logo" className="w-5 h-5" />,
		href: "https://twitter.com/CashCare_co",
	},
	{
		id: "socialList_4",
		icon: <FaTelegramPlane />,
		href: "https://t.me/CashCare12",
	},
];

export const footerLinks = [
	{
		id: "footer_col_1",
		name: "Company",
		options: [
			{
				id: "footer_col_1_1",
				text: "Home",
				href: "/",
			},
			{
				id: "footer_col_1_2",
				text: "About",
				href: "/about",
			},
			{
				id: "footer_col_1_3",
				text: "Whitepaper",
				href: "/",
			},
			// {
			// 	id: "footer_col_1_4",
			// 	text: "Terms & conditions",
			// 	href: "/",
			// },
		],
	},
	{
		id: "footer_col_2",
		name: "User/Business",
		options: [
			{
				id: "footer_col_2_1",
				text: "Register",
				href: "/register",
			},
			{
				id: "footer_col_2_5",
				text: "Sign In",
				href: "/login",
			},
			{
				id: "footer_col_2_3",
				text: "Contact Us",
				href: "/contact",
			},
			{
				id: "footer_col_2_4",
				text: "FAQs",
				href: "/faq",
			},
		],
	},
	{
		id: "footer_col_3",
		name: "Legal",
		options: [
			{
				id: "footer_col_3_1",
				text: "User Agreement",
				href: "/agreement",
			},
			{
				id: "footer_col_3_2",
				text: "Privacy Policy",
				href: "/privacy",
			},
			{
				id: "footer_col_3_3",
				text: "Terms & Conditions",
				href: "/terms",
			},
		],
	},
];
