import React, { useEffect } from "react";
import { Navbar, Sidebar } from "../../components/Shared";
import AboutHero from "../../components/About/AboutHero";
import AboutContent from "../../components/About/AboutContent";

function AboutPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className=" w-full">
			{" "}
			{/* bg-base_bg_1 bg-center */}
			<AboutHero />
			<AboutContent />
		</div>
	);
}

export default AboutPage;
